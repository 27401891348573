import {Icon, IconData, IconInterface} from '../Icon'
import {Media, MediaData, MediaInterface} from '../Media'
import PostThesis, {PostThesisData, PostThesisInterface} from './PostThesis'
import PostTag, {PostTagData, PostTagInterface} from './PostTag'
import {PageHeadData} from '../Page'
import {MediaVariant, MediaVariantData} from "../MediaVariant"
import PostListItem, {PostListItemData, PostListItemInterface} from "./PostListItem";
import Pagination, {PaginationData, PaginationInterface} from "../Paginator";
import {CommentableInterface, RatingableInterface, SocialShareableInterface} from "../interfaces";
import {Store, useStore} from "vuex";
import {RatingMetricsInterface} from "../rating/RatingMetrics";


export interface PostData {
  id: number
  tid: string
  adult: boolean
  age_rating: number
  allow_comment: boolean
  comment_count: number
  dislike_count: number
  icon: IconData | null
  icon_color: string | null
  like_count: number
  sort_num: number
  type_tid: string
  tags: Array<PostTagData>
  theses: Array<PostThesisData>
  view_count: number
  voice_count: number
  voice_sum: number
  voice_average: number
  header: string
  sub_header: string | null
  description: string
  introduction: string | null
  content: string
  image: MediaData | null,
  image_variants: Array<MediaVariantData>
  author: string | null
  source: string | null,
  published_at: string | null,
  updated_at: string | null,
  heads: Array<PageHeadData>
  title: string | null,
  schema: string,
}

export interface PostInterface extends SocialShareableInterface, RatingableInterface, CommentableInterface {
  id(): number

  tid(): string

  header(): string

  subHeader(): string | null

  hasTags(): boolean

  tags(): Array<PostTagInterface>

  type(): string

  hasTheses(): boolean

  theses(): Array<PostThesisInterface>

  icon(): IconInterface

  iconColor(): string | null

  introduction(): string

  image(): MediaInterface | null

  hasImageVariants(): boolean

  imageVariants(): Array<MediaVariant>

  content(): string

  description(): string

  schema(): string

  publishedAt(): Date | null,

  publishedAtIso(): string

  publishedAtLocaleDateString(locale: string): string

  updatedAt(): Date | null

  updatedAtIso(): string

  updatedAtLocaleDateString(locale: string): string

  source(): string | null

  author(): string | null

  allowComment(): boolean

  adult(): boolean

  ageRating(): number

  countDislikes(): number

  countLikes(): number

  countComments(): number

  countViews(): number

  countVoices(): number

  sumVoices(): number

  averageVoices(): number

  sortNum(): number

  heads(): Array<PageHeadData>

  path(): string
}

export default class Post implements PostInterface {

  private data: PostData
  private readonly _tags: Array<PostTagInterface>
  private readonly _theses: Array<PostThesisInterface>
  private readonly _icon: Icon
  private readonly _image: Media | null
  private readonly _image_variants: Array<MediaVariant>
  private readonly _publishedAt: Date | null
  private readonly _updatedAt: Date | null

  public constructor(data: PostData) {
    this.data = data

    this._tags = []
    if (Array.isArray(data.tags)) {
      for (let tag of data.tags) {
        this._tags.push(new PostTag(tag as PostTagData))
      }
    }

    this._theses = []
    if (Array.isArray(data.theses)) {
      for (let thesis of data.theses) {
        this._theses.push(new PostThesis(thesis as PostThesisData))
      }
    }

    this._icon = data.icon ? new Icon(data.icon as IconData) : new Icon({} as IconData)
    this._image = data.image ? new Media(data.image as MediaData) : null;
    this._publishedAt = data.published_at ? new Date(data.published_at) : null
    this._updatedAt = data.updated_at ? new Date(data.updated_at) : null
    this._image_variants = []
    if (Array.isArray(data.image_variants)) {
      for (let imgVariant of data.image_variants) {
        this._image_variants.push(new MediaVariant(imgVariant))
      }
    }
  }

  public id(): number {
    return this.data.id;
  }

  public tid(): string {
    return this.data.tid;
  }

  public header(): string {
    return this.data.header
  }

  public subHeader(): string | null {
    return this.data.sub_header
  }

  public hasTags(): boolean {
    return this._tags.length > 0
  }

  public tags(): Array<PostTagInterface> {
    return this._tags
  }

  public type(): string {
    return this.data.type_tid
  }

  public hasTheses(): boolean {
    return this._theses.length > 0
  }

  public theses(): Array<PostThesisInterface> {
    return this._theses
  }

  public icon(): IconInterface {
    return this._icon
  }

  public iconColor(): string | null {
    return this.data.icon_color
  }

  public introduction(): string {
    return this.data.introduction ?? ''
  }

  public image(): MediaInterface | null {
    return this._image
  }

  public hasImageVariants(): boolean {
    return this._image_variants.length > 0
  }

  public imageVariants(): Array<MediaVariant> {
    return this._image_variants
  }

  public content(): string {
    return this.data.content ?? ''
  }

  public description(): string {
    return this.data.description ?? ''
  }

  public schema(): string {
    return this.data.schema
  }

  public info(maxLen: number): string {
    return (this.data.description ?? this.data.introduction ?? '').substring(0, maxLen > 1 ? maxLen : 200)
  }

  public publishedAt(): Date | null {
    return this._publishedAt
  }

  public publishedAtIso(): string {
    return this._publishedAt
      ? this._publishedAt.toISOString()
      : ''
  }

  public publishedAtLocaleDateString(locale: string = 'ru'): string {
    return this._publishedAt
      ? this._publishedAt.toLocaleDateString(locale)
      : ''
  }

  public updatedAt(): Date | null {
    return this._updatedAt
  }

  public updatedAtIso(): string {
    return this._updatedAt
      ? this._updatedAt.toISOString()
      : ''
  }

  public updatedAtLocaleDateString(locale: string = 'ru'): string {
    return this._updatedAt
      ? this._updatedAt.toLocaleDateString(locale)
      : ''
  }

  public source(): string | null {
    return this.data.source
  }

  public author(): string | null {
    return this.data.author
  }

  public allowComment(): boolean {
    return this.data.allow_comment
  }

  public adult(): boolean {
    return this.data.adult
  }

  public ageRating(): number {
    return this.data.age_rating
  }

  public countDislikes(): number {
    return this.data.dislike_count
  }

  public countLikes(): number {
    return this.data.like_count
  }

  public countComments(): number {
    return this.data.comment_count
  }

  public countViews(): number {
    return this.data.view_count
  }

  public countVoices(): number {
    return this.data.voice_count
  }

  public sumVoices(): number {
    return this.data.voice_sum
  }

  public averageVoices(): number {
    return this.data.voice_average
  }

  setVoicesMetric(r: RatingMetricsInterface): void {
    this.data.voice_count = r.count()
    this.data.voice_sum = r.sum()
    this.data.voice_average = r.average()
  }

  public async updateStoreVoicesMetric(store: Store<unknown>, r: RatingMetricsInterface): Promise<void> {
    return store.commit('posts/postDataUpdateRating', {tid: this.tid(), r:r})
  }

  public sortNum(): number {
    return this.data.sort_num
  }

  public heads(): Array<PageHeadData> {
    return this.data.heads
  }

  public path(): string {
    return '/' + this.type() + '/' + this.tid() + '/'
  }

  public title(): string|null {
    return this.data.title
  }
}

export const postEmptyData: PostData = {
  id: 0,
  tid: '',
  adult: false,
  age_rating: 0,
  allow_comment: true,
  comment_count: 0,
  dislike_count: 0,
  icon: null,
  icon_color: null,
  like_count: 0,
  sort_num: 0,
  type_tid: 'blog',
  tags: [] as Array<PostTagData>,
  theses: [] as Array<PostThesisData>,
  view_count: 0,
  voice_count: 0,
  voice_sum: 0,
  voice_average: 0,
  header: '',
  schema: '',
  sub_header: null,
  description: '',
  introduction: null,
  content: '',
  image: null,
  image_variants: [] as Array<MediaVariantData>,
  author: null,
  source: null,
  published_at: null,
  updated_at: null,
  heads: [] as Array<PageHeadData>,
  title: null
}

export function postsBuildFromResponse(
  data: { data: Array<PostListItemData>, meta: PaginationData }
): { posts: Array<PostListItemInterface>, pagination: PaginationInterface } {
  let posts: Array<PostListItemInterface> = [];
  if (Array.isArray(data.data)) {
    for (let post of data.data) {
      posts.push(new PostListItem(post as PostListItemData))
    }
  }
  return {posts: posts, pagination: new Pagination(data.meta as PaginationData)}
}
