import MenuItem, {buildMenuItem, MenuItemData, MenuItemRelationship} from './MenuItem'

export interface MenuData {
  items: Array<MenuItemData>
  tid: string
  title: string | null
}

export interface MenuInterface {
  items(): Array<MenuItem>

  tid(): string

  title(): string | null
}

export default class Menu implements MenuInterface {
  protected readonly _items: Array<MenuItem> = []
  protected readonly _tid: string
  protected _title: string | null

  public constructor(tid: string, title: string | null) {
    this._tid = tid
    this._title = title
  }

  public addItem(item: MenuItem): void {
    this._items.push(item)
  }

  items(): Array<MenuItem> {
    return this._items
  }

  tid(): string {
    return this._tid
  }

  title(): string | null {
    return this._title
  }

}

export function castRelationshipFromString(value?: string | null): MenuItemRelationship | null {
  if (value === null || typeof value === 'undefined') {
    return null
  }

  let relationship: MenuItemRelationship | null = null

  switch (value) {
    case MenuItemRelationship.noopener:
      relationship = MenuItemRelationship.noopener
      break
    case MenuItemRelationship.nofollow:
      relationship = MenuItemRelationship.nofollow
      break
    case MenuItemRelationship.noreferrer:
      relationship = MenuItemRelationship.noreferrer
      break
    case MenuItemRelationship.opener:
      relationship = MenuItemRelationship.opener
      break
    default:
      console.info('Menu Item does not support relationship ' + value + '. Set to null')
  }
  return relationship;
}

export function buildTree(parentId: null | number, items: Array<MenuItemData>): Array<MenuItemData> {
  let tmp: Array<MenuItemData> = []
  for (let item of items) {
    if (item.menu_item_id === parentId) {
      let _items = buildTree(item.id, items)
      let _item = JSON.parse(JSON.stringify(item))
      _item.items = _items
      tmp.push(_item)
    }
  }
  return tmp;
}

export function buildMenu(data: MenuData): Menu {
  const item = new Menu(data.tid, data.title);
  for (let itemData of buildTree(null, data.items)) {
    item.addItem(buildMenuItem(itemData))
  }
  return item;
}
