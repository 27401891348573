import {MenuData} from '../../objects/menu/Menu'
import {getList} from './resource'

export const apiUrlMenus = '/v2/menus/'

export function getMenusData(templateTid?: string, templateAreaTid?: string, withItems?: boolean): Promise<Array<MenuData>> {
  let props = {}
  if (withItems === true) {
    props['withItems'] = true
  }
  return getList(
    apiUrlMenus,
    props,
    {template_tid: templateTid, template_area_tid: templateAreaTid},
    {page: 1, per_page: 'all'},
    null
  )
}

export function getMenuData(menuTid: string, templateTid: string, templateAreaTid: string, withItems ?: boolean): Promise<MenuData> {
  let props = {};
  if (withItems === true) {
    props['withItems'] = true
  }
  return getList(
    apiUrlMenus,
    props,
    {tid: menuTid, template_tid: templateTid, template_area_tid: templateAreaTid},
    {page: 1, per_page: 'all'},
    null
  ).then(
  data => {
    if (typeof data[0] !== 'undefined') {
      return data[0];
    } else {
      return Promise.reject({status: 500, data});
    }
  })
}
