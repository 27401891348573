<template>
  <ul :class="[`tes-sub-menu`, `tes-sub-menu_${layout}`, `tes-sub-menu_level-${level}`]" v-show="show">
    <li v-for="item in items" v-bind:key="item.id()"
        :class="[
          `tes-menu-sub-item`,
          `tes-menu-sub-item_${item.template()}`,
          item.css(),
          isFocusedOn(item) ? `tes-menu-sub-item_active` : ``,
          `tes-menu-item-${item.template()}`
          ]"
        @mouseleave="leaveAll($event)"
        @mouseenter="enter($event, item)">
      <a :href="item.link().value()" class="tes-menu-item-link"
      >{{ item.title() }}<template
        v-if="item.icon() && item.icon().id()">&nbsp;<IconView :icon="item.icon()" /></template></a>

      <!-- transition name="fade" -->
      <template v-if="item.hasItems()">
        <MainSubMenu :show="!isDropDown(item) || isFocusedOn(item)" :level="level + 1" :items="item.items()"  :layout="item.template()"/>
      </template>
      <!-- /transition -->
    </li>
  </ul>
</template>

<script lang="ts">
import {PropType, ref} from 'vue'
import MenuItem from '../../objects/menu/MenuItem'
import IconView from '../icon/IconView.vue'

const SubItemsTemplates: Array<string> = ['list-down','columns-down','groups-F-down','groups-R-down'];

export default {
  name: 'MainSubMenu',
  components: {IconView},
  props: {
    items: {
      type: Array as PropType<Array<MenuItem>>
    },
    show: {
      type: Boolean,
      default: false,
    },
    layout: {
      type: String,
      default: 'list-down'
    },
    level: {
      type: Number,
      default: 1
    }
  },
  setup() {
    let timer: number | null = null;
    const opened = ref({level: 0, path: null} as { level: number, path: string | null })
    const enter = (event: Event, item: MenuItem) => {
      opened.value.path = item.link().value()
      if (timer != null) {
        window.clearTimeout(timer)
        timer = null
      }
    }
    const leaveAll = (event: Event) => {
      timer = window.setTimeout(() => {
        opened.value.path = null
      }, 300)
    }
    const isFocusedOn = (item: MenuItem): boolean => {
      return opened.value.path === item.link().value()
    }
    const isDropDown = (item: MenuItem): boolean => {
      return SubItemsTemplates.indexOf(String(item.template())) > -1
    }
    return {isDropDown, isFocusedOn, leaveAll, enter}
  }
}
</script>
