import {getPostsData} from "../services/fetch/post";
import {PostListItemInterface} from "../objects/post/PostListItem";
import Pagination, {PaginationData} from "../objects/Paginator";
import {postsBuildFromResponse} from "../objects/post/Post";

import {ref} from "vue";
import {useRouter} from "vue-router";

export default function PostSearch(perPage: number = 10, pageSearchUrl: string = '/?s=') {
  const searchWord = ref('' as string)
  let lastSearchWord = ''
  const loading = ref(false)
  const visibleResults = ref(false)
  const pagination = ref(new Pagination({current_page: 1, per_page: perPage} as PaginationData))
  const posts = ref([] as Array<PostListItemInterface>)
  const valid = (): boolean => {
    return searchWord.value.trim().length > 3
  }
  const changed = (): boolean => {
    return lastSearchWord.trim() !== searchWord.value.trim()
  }
  let timer: number | null = null
  const stopTimer = () => {
    if (timer !== null) {
      window.clearTimeout(timer)
    }
    timer = null
  }
  const search = () => {
    if (changed()) {
      stopTimer()
      visibleResults.value = false
      if (valid()) {
        timer = window.setTimeout(() => {
          loading.value = true

          getPostsData({media: 'search'}, pagination.value.paginator(), null, {search: searchWord.value.trim()})
            .then(data => {
              const d = postsBuildFromResponse(data)
              posts.value = d.posts
              pagination.value = d.pagination
              visibleResults.value = true
              lastSearchWord = searchWord.value
            }).finally(() => loading.value = false)
        }, 1000)
      }
    }
  }
  const clear = () => {
    visibleResults.value = false
    posts.value = []
    searchWord.value = lastSearchWord = ''
    stopTimer()
  }

  const enter = () => {
    visibleResults.value = posts.value.length > 0 && valid()
  }

  const leave = () => {
    visibleResults.value = false
  }

  const router = useRouter()
  const submit = () => {
    if (valid()) {
      //router.push(`${pageSearchUrl}${searchWord.value}`).catch(err => console.log(err))
      window.location.href = `${pageSearchUrl}${searchWord.value}`
    }
    return false
  }
  return {
    visibleResults, enter, leave,
    searchWord, loading, posts, pagination,
    search, clear, submit
  }
}
