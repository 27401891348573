import {prepareQuery} from '../../objects/utils'
import clientHeaders from '../../client.headers'
import {PaginatorData} from '../../objects/Paginator'
import fetch from 'cross-fetch'

export function getList(path: string, props: object | null, filter: object | null, paginator: PaginatorData | null, sort: object | null): Promise<any> {
  let headers = clientHeaders()
  headers['X-Requested-With'] = 'XMLHttpRequest'
  headers['Accept'] = 'application/json'
  return fetch(
    `${import.meta.env.VITE_APP_SERVER_API_URL}${path}?${prepareQuery({...props, ...paginator, sort, filter})}`,
    {
      headers: headers as Headers
    }
  )
    .then(
      response => {
        return response.json().then(data => {
          if (response.ok) {
            return data.data;
          } else {
            return Promise.reject({status: response.status, data});
          }
        });
      }
    )
}

export function getOneByID(path: string, id: Number | string, props: object | null): Promise<any> {
  let headers = clientHeaders()
  headers['X-Requested-With'] = 'XMLHttpRequest'
  headers['Accept'] = 'application/json'
  return fetch(
    `${import.meta.env.VITE_APP_SERVER_API_URL}${path}${id}` + (props ? `?${prepareQuery(props)}` : ''),
    {
      headers: headers as Headers
    }
  )
    .then(
      response => {
        return response.json().then(data => {
          if (response.ok) {
            return data.data;
          } else {
            return Promise.reject({status: response.status, data});
          }
        });
      }
    )
}

export function saveData(path: string, data: any): Promise<any> {
  let headers = clientHeaders()
  headers['X-Requested-With'] = 'XMLHttpRequest'
  headers['Accept'] = 'application/json'
  headers['Content-Type'] = 'application/json'

  return fetch(
    `${import.meta.env.VITE_APP_SERVER_API_URL}${path}`,
    {
      mode: 'cors',
      method: 'POST',
      credentials: 'include',
      body: JSON.stringify(data),
      headers: headers as Headers
    }
  )
    .then(
      response => {
        return response.json().then(data => {
          if (response.ok) {
            return data.data;
          } else {
            return Promise.reject({status: response.status, data});
          }
        });
      }
    )
}
