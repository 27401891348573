import {getList, getOneByID} from './resource'
import {PostData} from '../../objects/post/Post'
import {PostListItemData} from '../../objects/post/PostListItem'
import {PaginationData, PaginatorData} from '../../objects/Paginator'

export const apiUrlPosts = '/v2/posts/'

export function getPostsData(
  props: object | null,
  paginator: PaginatorData | null,
  sort: object | null,
  filter: object | null
): Promise<{ data: Array<PostListItemData>, meta: PaginationData }> {
  return getList(apiUrlPosts, props, filter, paginator, sort)
}

export function getPostDataById(id: number | string, props: object | null): Promise<PostData> {
  return getOneByID(apiUrlPosts, id, props)
}
