<template>

  <footer class="tes-footer tes-wrapper container-fluid">

    <div class="tes-footer-wrapper container">

      <ObserverComponent>

        <div class="tes-footer__first-column">

          <div class="tes-footer__logos">
            <a href="/" class="tes-footer-logo tes-footer-logo_tion" title="Tion logo">
              <TionLogo/>
            </a>
            <a href="https://sk.ru/" rel="noreferrer" class="tes-footer-logo test-footer-logo_sk" title="Skolkovo logo">
              <SkolkovoLogo/>
            </a>
          </div>

          <SiteSocialColor/>

        </div>

        <div class="tes-footer__second-column">
          <Suspense>
            <template #default>
              <FooterMenu template="blog"/>
            </template>
            <template #fallback>
              <EllipsisLoader :loading="true" class="tes-loader__overlay_inner"/>
            </template>
          </Suspense>
        </div>

        <div class="tes-footer__third-column">
          <p class="subscribe-header">Подписка на полезные статьи</p>
          <MailSubscriptionForm/>
          <p class="subscribe-agreements-text">Нажав кнопку «Подписаться», Вы даете
            <a style="color: #03b8fa;"
               href="/doc/general/soglasie_personal_data_new-redaction.pdf"
            >согласие на обработку своих персональных данных</a>
          </p>
        </div>

      </ObserverComponent>

      <div class="tes-footer__copyright">
        <div itemscope itemtype="https://schema.org/Organization" class="tes-footer__copyright-wrapper">
          <div itemprop="address" itemscope itemtype="https://schema.org/PostalAddress">
            <span itemprop="postalCode">109544</span>, г. <span itemprop="addressLocality">Москва</span>, ул. <span
            itemprop="streetAddress">Рабочая, 93, стр. 2</span>
            <br/>
            <a href="/shop/offero/" target="_blank">Пользовательское соглашение</a>
            <br/>
            <a href="/doc/general/privacy_policy_person.pdf"
               target="_blank">Политика конфиденциальности персональной информации</a>
            <meta itemprop="telephone" content="8 (499) 288-14-70">
          </div>
          <p itemprop="name" class="tion-year">ТИОН Умный микроклимат © {{ getCurrentYear() }}</p>
          <meta itemprop="image" content="https://storage.yandexcloud.net/cdn.tion.ru/images/blog/generated/2017/03/mir_klimata001-main.jpg">
          <div itemprop="address" itemscope itemtype="https://schema.org/PostalAddress">
            <meta itemprop="postalCode" content="115114">
            <meta itemprop="addressLocality" content="г. Москва">
            <meta itemprop="streetAddress" content="ул. Летниковская, 10, стр. 1">
            <meta itemprop="telephone" content="8 (499) 653-88-63">
          </div>
        </div>
      </div>

    </div>

    <AppVersionView/>

  </footer>

</template>

<script lang="ts">

import {defineAsyncComponent} from "vue";
import AppVersionView from '../../components/AppVersionView.vue'
import MailSubscriptionForm from "../../components/form/MailSubscriptionForm.vue"
import SiteSocialColor from "../../components/SiteSocialColor.vue"

import TionLogo from '~/assets/logos/logo-tion-simple.svg'
import SkolkovoLogo from '~/assets/logos/sk-tion.svg'

export default {
  name: 'SiteFooter',
  components: {
    AppVersionView,
    FooterMenu: defineAsyncComponent({
      loader: () => import('../../components/menu/FooterMenu.vue'),
      delay: 3000,
      timeout: 1000
    }),
    MailSubscriptionForm,
    SiteSocialColor,
    TionLogo,
    SkolkovoLogo
  },
  methods: {
    getCurrentYear() {
      return new Date().getFullYear();
    }
  }
}
</script>
