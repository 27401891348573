import {PostTagData} from '../objects/post/PostTag'
import {getPostsTagsData} from '../services/fetch/post-tags'
import {generateKeyForObject} from '../objects/utils'

export function createPostsTagsStore() {
  return {
    namespaced: true,
    state: {
      listOfGroupedTags: {} as { [key: string]: Array<PostTagData> },
    },
    mutations: {
      listOfGroupedTagsData(state, payload: { key: string, tags: Array<PostTagData> }) {
        state.listOfGroupedTags[payload.key] = payload.tags
      },
    },
    getters: {
      getDataForListOfGroupedTags: (state) => (key): Array<PostTagData> | null => {
        return state.listOfGroupedTags[key] ?? null
      },
    },
    actions: {
      loadDataForTags({getters, commit}, {props, paginator, sort, filter}): Promise<Array<PostTagData>> {
        let key = generateKeyForObject({props, paginator, sort, filter})
        let tagsData = getters['getDataForListOfGroupedTags'](key)
        if (tagsData) {
          return (new Promise<Array<PostTagData>>((resolve) => resolve(tagsData)))
        }
        return getPostsTagsData(props, paginator, sort, filter)
          .then(async data => {
            await commit('listOfGroupedTagsData', {key: key, tags: data})
            return data
          })
      }
    },
  }
}
