import GoalItem, {GoalItemData} from "../GoalItem";
import {Icon, IconData, IconInterface} from "../Icon";
import {MenuItemLink, MenuItemLinkData} from "./MenuItemLink";

export enum MenuItemRelationship {
  nofollow = 'nofollow',
  noopener = 'noopener',
  noreferrer = 'noreferrer',
  opener = 'opener'
}

export interface MenuItemData {
  css?: string | null
  goals?: Array<GoalItemData>
  icon?: IconData | null
  id: number,
  items?: Array<MenuItemData>
  link: MenuItemLinkData
  menu_item_id: number | null,
  sort_num: number | 0,
  group_num: number | 0,
  relationship?: MenuItemRelationship | null
  template?: string,
  title?: string | null
}

export interface MenuItemInterface {

  addItem(item: MenuItem): void

  addGoal(item: GoalItem): void

  css(): string

  goals(): Array<GoalItem>

  icon(): IconInterface

  id(): number

  items(): Array<MenuItem>

  hasItems(): boolean

  link(): MenuItemLink

  relationship(): MenuItemRelationship | null

  template(): string,

  title(): string | null

  sortNum(): number | 0

  groupNum(): number | 0
}

export default class MenuItem implements MenuItemInterface {

  private readonly data: MenuItemData

  private readonly _icon: IconInterface
  private readonly _items: Array<MenuItem> = []
  private readonly _goals: Array<GoalItem> = []
  private readonly _link: MenuItemLink

  public constructor(data: MenuItemData) {
    this.data = data;
    this._link = new MenuItemLink(data.link.value, data.link.css)
    this._icon = data.icon ? new Icon(data.icon as IconData) : new Icon({} as IconData)
  }

  public addItem(item: MenuItem): void {
    this._items.push(item)
  }

  public addGoal(item: GoalItem): void {
    this._goals.push(item)
  }

  public css(): string {
    return this.data.css ?? ''
  }

  public goals(): Array<GoalItem> {
    return this._goals
  }

  public icon(): IconInterface {
    return this._icon
  }

  public id(): number {
    return this.data.id
  }

  public items(): Array<MenuItem> {
    return this._items
  }

  public hasItems(): boolean {
    return this._items.length > 0
  }

  public link(): MenuItemLink {
    return this._link
  }

  public relationship(): MenuItemRelationship | null {
    return this.data.relationship ?? null
  }

  public setCss(value: string | null): void {
    this.data.css = value
  }

  public setRelationship(value: MenuItemRelationship | null): void {
    this.data.relationship = value
  }

  public setTitle(value: string | null): void {
    this.data.title = value
  }

  public template(): string {
    return this.data.template ?? 'list-down'
  }

  public title(): string | null {
    return this.data.title ?? null
  }

  public sortNum(): number {
    return this.data.sort_num ?? 0
  }

  public groupNum(): number {
    return this.data.group_num ?? 0
  }
}

export function buildMenuItem(data: MenuItemData): MenuItem {
  const item = new MenuItem(data)
  if (Array.isArray(data.goals)) {
    for (let goalData of data.goals) {
      item.addGoal(new GoalItem(goalData))
    }
  }
  if (Array.isArray(data.items)) {
    for (let itemData of data.items) {
      item.addItem(buildMenuItem(itemData))
    }
  }
  return item;
}
