<style lang="scss" scoped>
.subscribe-form {
  button {
    width: 100%;
    height: 37px;
    margin: 10px auto auto;
    line-height: 33px;
    font-size: 14px;
    padding: 0;
    background-color: $themeMainColor;
    transition: background-color .2s;
    color: #11404e;
    border: 0;
    cursor: pointer;
  }

  input[type="text"],
  input[type="email"] {
    padding: 5px 10px;
    width: 100%;
    height: 37px;
    border: 1px solid #e6e6e6;
    display: block;
    font-size: 1rem;
    line-height: 1.25;
    color: #464a4c;
    background-color: #fff;
    background-image: none;
    -webkit-background-clip: padding-box;
    background-clip: padding-box;
    border-radius: 0.25rem;
    -webkit-transition: border-color ease-in-out 0.15s, -webkit-box-shadow ease-in-out 0.15s;
    transition: border-color ease-in-out 0.15s, -webkit-box-shadow ease-in-out 0.15s;
    -o-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
    transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
    transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s, -webkit-box-shadow ease-in-out 0.15s;
  }
}
</style>

<template>
  <div class="mail-subscribe-wrapper">

    <transition name="fade">
      <div class="errors" v-show="hasError()"><p>Возникла проблема при отправке данных.</p></div>
    </transition>

    <transition name="fade">
      <form method="post" action="/subscribe" class="subscribe-form" @submit.prevent="subscribe" v-show="!success">
        <input type="email" placeholder="E-mail" class="form-control"
               v-model="subscribeData.email" name="uemail" aria-required="true" aria-invalid="false"/>
        <button type="submit">Подписаться</button>
      </form>
    </transition>

    <transition name="fade">
      <div class="success" v-show="success"><p>ОТЛИЧНО! ЖДИТЕ НОВОСТЕЙ!</p></div>
    </transition>

  </div>
</template>

<script lang="ts">
import MailSubscribe from '../../composables/mail-subscribe'

export default {
  name: 'MailSubscriptionForm',
  setup() {return MailSubscribe()}
}
</script>
