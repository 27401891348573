import {getList} from './resource'
import {PaginatorData} from '../../objects/Paginator'
import {PostTagData} from "../../objects/post/PostTag";

export const apiUrlPostsTags = '/v2/post-tags/'

export function getPostsTagsData(
  props: object | null,
  paginator: PaginatorData | null,
  sort: object | null,
  filter: object | null
): Promise<Array<PostTagData>> {
  return getList(apiUrlPostsTags, props, filter, paginator, sort)
}
