<script lang="ts">
import {h} from "vue";

export default {
  name: 'AppVersionView',
  setup() {
    return () => {
      return h('div', {style: "text-align: right; font-size: 10px;padding-right: 15px;"}, APP_VERSION)
    }
  }
}
</script>
