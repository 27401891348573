export interface LayoutInterface {
  readonly tid: string,
  template() : Promise<{ default: any }>
}

export const layoutDefault = {
  tid: 'layout-default',
  template: () => import('@/layouts/LayoutDefault.vue')
} as LayoutInterface

export const layoutBlog = {
  tid: 'layout-blog',
  template: () => import('@/layouts/LayoutBlog.vue')
} as LayoutInterface

export const layoutBlogInner = {
  tid: 'layout-inner-blog',
  template: () => import('@/layouts/LayoutBlogInner.vue')
} as LayoutInterface
