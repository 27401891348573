<script lang="ts">
import {LazyLoadState} from '../../objects/LazyImage'
import {ref, onMounted, h, reactive, onBeforeUnmount} from 'vue'

import EllipsisLoader from '../loader/EllipsisLoader.vue'

export default {
  name: "ObserverComponent",
  components: {
    EllipsisLoader
  },
  emits: ['intersect'],
  setup(props, {emit, slots}) {
    const anchor = ref<HTMLElement | null>(null)
    let state = reactive({
      observer: null,
      intersected: false,
      loaded: false,
    } as LazyLoadState)

    const start = () => {
      if (window) {
        if (anchor.value && typeof IntersectionObserver !== 'undefined') {
          state.observer = new IntersectionObserver((entries) => {
            const item = entries[0]
            if (item.isIntersecting) {
              state.observer?.disconnect()
              state.observer = null

              state.intersected = true
              state.loaded = true

              emit('intersect')
            }
          }, props.intersectionOptions)
          state.observer.observe(anchor.value)
        } else {
          state.observer = null
          state.intersected = true
          state.loaded = true
        }
        window.removeEventListener('scroll', start)
        window.removeEventListener('touchstart', start)
        document.removeEventListener('mouseenter', start)
        document.removeEventListener('click', start)
        //window.removeEventListener('DOMContentLoaded', start)
      }
    }

    onMounted(() => {
      if (window && !state.intersected) {
        window.addEventListener('scroll', start)
        window.addEventListener('touchstart', start)
        document.addEventListener('mouseenter', start)
        document.addEventListener('click', start)
        //window.addEventListener('DOMContentLoaded', start)
      }
    })

    onBeforeUnmount(() => {
      if (state.observer) {
        state.observer.disconnect()
        state.observer = null
      }
      if (window) {
        window.removeEventListener('scroll', start)
        window.removeEventListener('touchstart', start)
        document.removeEventListener('mouseenter', start)
        document.removeEventListener('click', start)
        //window.removeEventListener('DOMContentLoaded', start)
      }
    })

    return () => {
      return !state.intersected
        ? h('div',{ref: anchor, style: 'position: relative;'}, h(EllipsisLoader, {loading:true}))
        : h(slots.default) /*h(KeepAlive, null, h(slots.default))*/
    }
  }
}
</script>
