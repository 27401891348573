import {Size, SizeData} from './Size'
import {allAsNullOrString} from './utils'

export enum IconSrcType {storage = 'storage', style = 'style', css = 'css'}

export interface IconData {
  id?: number | null,
  type: IconSrcType
  alt?: string | null
  dimensions?: SizeData | null
  url: string | null
  style: string | null
  css: string | null
}

export interface IconInterface {
  id: () => number | null | undefined,
  alt: () => string | null | undefined
  dimensions: () => Size
  type: () => IconSrcType
  url: () => string | null
  style: () => string
  css: () => string
  prepareCss: () => string
  prepareFullStyle: () => string
}

export class Icon implements IconInterface {
  private data: IconData
  private _size: Size


  // TODO make validation of income values
  public constructor(data: IconData) {
    this.data = data
    this._size = new Size(data.dimensions ? data.dimensions as SizeData : {} as SizeData)
  }

  public id(): number | null | undefined {
    return this.data.id
  }

  public alt(): string | null | undefined {
    return this.data.alt
  }

  public setAlt(value: string | null) {
    this.data.alt = allAsNullOrString(value)
  }

  public dimensions(): Size {
    return this._size
  }

  public type(): IconSrcType {
    return this.data.type
  }

  public url(): string | null {
    return this.data.url
  }

  public style(): string {
    return this.data.style ?? ''
  }

  public css(): string {
    return this.data.css ?? ''
  }

  public prepareForFontawesomeCssAr(): Array<string> {
    if (this.css()) {
      let parts = this.css()?.replace(/fa\-/, '')
        //.replace('fas', 'fab')
        .trim()
        .split(' ');
      return parts ?? []
    }
    return ['fab', 'wind']
  }

  public prepareCss(): string {
    if (this.css()) {
      if (this.css().indexOf('fas') > -1 || this.css().indexOf('fab') > -1 || this.css().indexOf('far') > -1) {
        return this.css()
          .replace('fas', 'fa')
          .replace('fab', 'fa')
          .replace('far', 'fa')
          .trim();
      } else {
        return this.css();
      }
    }
    return 'fa fa-wind'
  }

  public prepareFullStyle(): string {
    let styles: Array<string> = []
    if (this.style()) {
      styles.push(this.style())
    }
    let w = this.dimensions().width()
    if (w) {
      styles.push(`width:${w}px`)
    }
    let h = this.dimensions().height()
    if (this.dimensions().height()) {
      styles.push(`height:${h}px`)
    }
    return styles.join(';')
  }
}
