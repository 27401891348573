<template>

    <transition name="toggle-left">
      <nav v-show="visibleSideMenu" class="tes-navigation-t2">
        <ObserverComponent>
          <div class="tes-navigation-t2-wrapper">

            <div class="tes-navigation-t2-header">
              <router-link to="/" title="На главную сайта" class="tes-navigation-t2__logo-link">
                <LogoIconWhite class="tes-navigation-t2__logo-icon"
                               title="Логотип интернет-магазина ООО &quot;ХК Тион&quot;"/>
              </router-link>
              <button class="tes-navigation-t2__close-button" type="button" title="Закрыть панель меню"
                      @click="closeSideMenu">
                <CloseIcon class="tes-navigation-t2__close-icon"/>
                <span class="not-for-anyone" aria-hidden="true">Закрыть панель меню</span>
              </button>
            </div>


            <ul class="tes-navigation-t2-menu">
              <li><a href="/product/breezer/">Бризеры</a></li>
              <li><a href="/promo/">Акции</a></li>
              <li><a href="/help/delivery/">Доставка</a></li>
              <li><a href="/about/">О компании</a></li>
              <li><a href="/about/contact/">Контакты</a></li>
            </ul>

            <!-- div class="">
              <router-link to="/account/" class=""
                           title="Ссылка для входа в личный кабинет"
              >Вход <span class="login-img"><EntranceIcon/></span></router-link>
            </div -->

            <div class="tes-navigation-t2__phone">
              <a href="tel:+78005006897" class=""
                 title="телефон горячей линии нтернет-магазина ООО &quot;ХК Тион&quot;">8 (800) 500-6897</a>
            </div>

            <!-- div class="tes-navigation-t2-shop">
              <router-link to="/shop/cart/"
                           class="">
                <ShopCartIcon title="Карзина интернет-магазина ООО &quot;ХК Тион&quot;"/>
              </router-link>
              <router-link to="/shop/" class=""
                           title="Интернет-магазин ООО &quot;ХК Тион&quot;">Магазин
              </router-link>
            </div -->

            <MainPostSearchForm class="tes-navigation-t2__search"/>

            <TagsLinksList class="tes-navigation-t2-tags"/>

          </div>
        </ObserverComponent>
      </nav>
    </transition>

  <header class="tes-header tes-header_blog container">

    <div class="tes-logo">

      <button type="button" class="tes-header__menu-button" @click="openSideMenu"
              title="Скрыть/Показать панель меню">
        <BarsIcon class="tes-header__menu-button-icon" title="Полоски меню" style="max-width: 30px;"/>
        <span class="not-for-anyone" aria-hidden="true">Скрыть/Показать панель меню</span>
      </button>

      <a class="tes-logo__link" href="/"
         title="На главную сайта"><LogoIcon class="tes-logo__icon"
                                            style="max-width: 100px;"
                                            title="Логотип интернет-магазина ООО &quot;ХК Тион&quot;"/></a>

    </div>

    <Suspense>
      <template #default>
        <MainMenu class="tes-menu-main" template="standard"/>
      </template>
      <template #fallback>
        <EllipsisLoader :loading="true" class="tes-loader__overlay_inner"/>
      </template>
    </Suspense>

    <div class="tes-phone-contacts">

      <a id="watsApp-btn" target="_blank" href="https://wa.me/79232414851"
         class="phone-contact tes-icon-wrapper tes-icon-wrapper_26x26"
         onclick="window.fbq('track', 'Contact', {content_name: 'WhatsApp'});" rel="noreferrer"
         title="Открыть WatsApp 7(923)241-48-51" aria-label="Открыть WatsApp 7(923)241-48-51">
        <WhatsAppIcon class="tes-icon26x26" style="max-width: 26px;"/>
        <span class="phone-title not-for-anyone" aria-hidden="true">WatsApp ТИОН - 7(923)241-48-51</span>
      </a>

      <a class="tes-icon-wrapper mgo-number-13230 default phone-contact " href="tel:+78005006029"
         title="Позвонить 8(800)500-6029" aria-label="Позвонить 8(800)500-6029">
        <MobilePhoneIcon class="tes-icon16x26" style="max-width: 16px;"/>
        <span class="phone-title" aria-hidden="true">8 (800) 500-6029</span>
      </a>

      <a class="tes-icon-wrapper phone-contact hotels" href="tel:+79039419007" v-show="false"
         title="Позвонить 7(903)941-90-07" aria-label="Позвонить 7(903)941-90-07">
        <MobilePhoneIcon class="tes-icon16x26" style="max-width: 16px;"/>
        <span class="phone-title" aria-hidden="true">7 (903) 941-9007</span>
      </a>

    </div>

    <div class="tes-user-activity">

      <WhereBuy class="tes-where-buy-menu"/>

      <a class="tes-icon-wrapper tes-signin" title="Вход в Личный кабинет" href="/shop/account/">
        <PersonalCabinetIcon class="tes-icon29x26 tes-signin-icon" style="max-width: 29px;"/>
      </a>

      <ShopCart/>
    </div>

  </header>

</template>

<script lang="ts">
import {defineAsyncComponent, ref, watch} from 'vue'
import WhereBuy from '../../components/WhereBuy.vue'
import ShopCart from '../../components/shop/ShopCart.vue'
import MainPostSearchForm from '../../components/post/MainPostSearchForm.vue'
import TagsLinksList from '../../components/post/TagsLinksList.vue'


import CloseIcon from '~/assets/icons/close.svg'
import LogoIcon from '~/assets/logos/logo-tion-simple.svg'
import LogoIconWhite from '~/assets/logos/logo-tion-white.svg'
import EntranceIcon from '~/assets/icons/entrance-n.svg'
import BarsIcon from '~/assets/icons/bars-n.svg'
import ShopCartIcon from '~/assets/icons/cart-n.svg'
import SearchIcon from '~/assets/icons/search-n.svg'

import PersonalCabinetIcon from '~/assets/icons/entrance.svg'
import WhatsAppIcon from '~/assets/social/whatsapp.svg'
import MobilePhoneRingIcon from '~/assets/icons/phone-ring.svg'
import MobilePhoneIcon from '~/assets/icons/phone.svg'
import {useRoute} from "vue-router";

export default {
  name: 'SiteHeaderBlogPost',
  components: {
    MainPostSearchForm,
    TagsLinksList,
    ShopCart,
    WhereBuy,
    MainMenu: defineAsyncComponent({
      loader: () => import('~/components/menu/MainMenu.vue'),
      delay: 2000,
      timeout: 1000
    }),
    PersonalCabinetIcon,
    WhatsAppIcon,
    MobilePhoneRingIcon,
    MobilePhoneIcon,
    CloseIcon,
    LogoIcon,
    LogoIconWhite,
    EntranceIcon,
    BarsIcon,
    ShopCartIcon,
    SearchIcon
  },
  setup() {
    const route = useRoute()
    const visibleSideMenu = ref(false)
    const closeSideMenu = () => {
      visibleSideMenu.value = false
    }
    const openSideMenu = () => {
      visibleSideMenu.value = true
    }
    watch(() => route.path, path => {
      closeSideMenu()
    })
    return {visibleSideMenu, closeSideMenu, openSideMenu}
  }
}
</script>

<style lang="scss" scoped>
.tes-header_blog {
  @media (max-width: 992px) {
    grid-template: "logo phones activities";
    grid-template-columns: 140px calc( 100% - 140px - 211px) 211px;
    grid-template-rows: 50px;
    min-height: 50px;
  }
  @media (max-width: 576px) {
    grid-template-columns: 140px calc( 100% - 140px - 75px) 75px;
  }
  @media (max-width: 435px) {
    grid-template-columns: 140px calc( 100% - 140px - 75px) 75px;
  }
}

.tes-header__menu-button {
  outline: none;
  display: block;
  height: 36px;
  width: 30px;
  min-width: 30px;
  padding: 0;
  margin-right: 8px;
  border: none;
  background-color: transparent;

  &:focus {
    outline: none;
    border: none;
  }

  &-icon {
    width: 100%;
    height: auto;
  }
}

.tes-logo {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;

  &__icon {
    width: 100px;
    height: auto;
  }
}
</style>
