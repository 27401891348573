import fetch from 'cross-fetch';
import {prepareQuery} from "../../objects/utils";
import clientHeaders from "../../client.headers";
import {PaginationData, PaginatorData} from "../../objects/Paginator";
import {CommentData, CommentVariantProp} from "../../objects/comment/Comment";
import {saveData} from "./resource";

export const apiUrlComments = '/v2/comments/'

export function getCommentsData(
  type: CommentVariantProp,
  paginator: PaginatorData | null,
  sort: object | null,
  filter: object | null
): Promise<{ data: Array<CommentData>, meta: PaginationData }> {
  let headers = clientHeaders()
  headers['X-Requested-With'] = 'XMLHttpRequest'
  headers['Accept'] = 'application/json'
  return fetch(
    `${import.meta.env.VITE_APP_SERVER_API_URL}${apiUrlComments}${type.owner_type}/${type.owner_id}?${prepareQuery({
      ...paginator,
      sort,
      filter
    })}`,
    {
      headers: headers as Headers
    }
  )
    .then(
      response => {
        return response.json().then(data => {
          if (response.ok) {
            return data.data;
          } else {
            return Promise.reject({status: response.status, data});
          }
        });
      }
    )
}

export function saveCommentData(comment: CommentData): Promise<CommentData> {
  return saveData(apiUrlComments, comment)
}
