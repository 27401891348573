import {LayoutInterface} from "../objects/Layout";
export function createLayoutStore() {
  return {
    namespaced: true,
    state: {
      layoutData: null,
      layout: null,
    },
    mutations: {
      updateData(state, layoutData) {
        state.layoutData = layoutData
      },
      update(state, layout) {
        state.layout = layout
      },
    },
    actions: {
      changeData({commit}, layoutData) {
        commit('updateData', layoutData)
      },
      change({commit}, layout) {
        commit('update', layout)
      },
    },
    getters: {
      layoutData(state): LayoutInterface | null {
        return state.layoutData
      },
      layout(state): any {
        return state.layout
      },
    }
  }
}
