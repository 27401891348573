export interface PaginatorData {
  page: number,
  per_page: number | string
}

export interface PaginationData {
  total: number,
  count: number,
  per_page: number,
  current_page: number,
  total_pages: number
}


export interface PaginationInterface {
  total(): number

  count(): number

  perPage(): number

  currentPage(): number

  totalPages(): number

  paginator(): PaginatorData

  firstPage(): number

  prevPage(): number

  nextPage(): number

  lastPage(): number

  isLastPage(): boolean

  isFirstPage(): boolean

  setCurrentPage(page: number): void
}

export default class Pagination implements PaginationInterface {
  private data: PaginationData

  public constructor(data: PaginationData) {
    this.data = data
  }

  public total(): number {
    return this.data.total
  }

  public count(): number {
    return this.data.count
  }

  public perPage(): number {
    return this.data.per_page
  }

  public currentPage(): number {
    return this.data.current_page
  }

  public totalPages(): number {
    return this.data.total_pages
  }

  public paginator(): PaginatorData {
    return {
      page: this.currentPage(),
      per_page: this.perPage()
    } as PaginatorData
  }

  public firstPage(): number {
    return 1;
  }

  public prevPage(): number {
    return this.data.current_page - 1
  }

  public nextPage(): number {
    return this.data.current_page + 1 > this.totalPages() ? 0 : this.data.current_page + 1
  }

  public lastPage(): number {
    return this.totalPages()
  }

  public isLastPage(): boolean {
    return this.currentPage() >= this.totalPages()
  }

  public isFirstPage(): boolean {
    return this.currentPage() <= 1
  }

  setCurrentPage(page: any): void {
    page = ['number', 'string'].indexOf(typeof page) !== -1 ? parseInt(page) : 0
    this.data.current_page = !isNaN(page) ? page : 0
  }
}
