import {createSite} from './main'
//import {isPromise} from "./objects/utils";
//import {isObject} from "@vue/test-utils/dist/utils";

/*
router.beforeResolve(async (to, from, next) => {

  let diffed = false;
  const matched = router.resolve(to).matched
  const prevMatched = router.resolve(from).matched

   const activated = matched.filter((c, i) => {
     return diffed || (diffed = prevMatched[i] !== c);
   })

   next()
})
*/

//
// router.beforeResolve(async (to, from, next) => {
//   console.log('    BEGIN    CLIENT beforeResolve')
//
//   console.log(to, from)
//
//   if (from && !from.name) { // перехода еще не было
//     return next();
//   }
//
//   let diffed = false;
//   const matched = router.resolve(to).matched;
//   const prevMatched = router.resolve(from).matched;
//
//   const activated = matched.filter((c, i) => {
//     return diffed || (diffed = prevMatched[i] !== c);
//   });
//
//   console.log('    FOUND  activated: ' , activated);
//   if (!activated.length) {
//     return next();
//   }
//
//   const matchedComponents: any = [];
//   matched.forEach(route => {
//     matchedComponents.push(...Object.values(route.components));
//   });
//
//   const asyncDataFuncs = matchedComponents.map((component: any) => {
//     const asyncData = component.asyncData;
//
//     if (!asyncData) {
//       return;
//     }
//
//     const config = {
//       //store,
//       route: to
//     };
//
//     if (!isPromise(asyncData)) {
//       return Promise.resolve(asyncData(config));
//     }
//
//     return asyncData(config);
//   });
//
//   try {
//     await Promise.all(asyncDataFuncs).then(() => {
//       next();
//     });
//   } catch (err) {
//     next(err as any);
//   }
// });

let {app, head, router, store} = createSite()

router.isReady().then(() => {
  if (typeof window['__INITIAL_STATE__'] === 'object' && Object.keys(window['__INITIAL_STATE__']).length > 0) {
    store.replaceState(window['__INITIAL_STATE__']);
  }
  app.mount('#tion_app', true)
})
