<template>
  <SiteHeaderBlogPost/>
  <main class="tes-body" id="_tes_content_">
    <slot/>
  </main>
  <SiteFooter class="bg-blue-05"/>
</template>

<script>
import SiteHeaderBlogPost from './blog/SiteHeaderBlogPost.vue'
import SiteFooter from './default/SiteFooter.vue'

export default {
  name: 'LayoutBlog',
  components: {
    SiteHeaderBlogPost,
    SiteFooter
  }
}
</script>
