import Menu, {MenuData} from '../objects/menu/Menu'
import {getMenuData, getMenusData} from '../services/fetch/menu'
import {buildMenu, buildTree} from '../objects/menu/Menu'
import MenuItem from '../objects/menu/MenuItem'

export function createMenusStore() {
  return {
    namespaced: true,
    state: {
      listOfDataForGroupedMenus: {} as { [key: string]: Array<MenuData> },
      listOfDataForMenus: {} as { [key: string]: MenuData },
      listOfGroupedMenus: {} as { [key: string]: Array<Menu> },
      listOfMenus: {} as { [key: string]: Menu },
    },
    getters: {
      getDataForListOfGroupedMenus: (state) => (key): Array<MenuData> | null => {
        return state.listOfDataForGroupedMenus[key] ?? null
      },
      getDataForListOfMenus: (state) => (key): MenuData | null => {
        return state.listOfDataForMenus[key] ?? null
      },
      getListOfGroupedMenus: (state) => (key): Array<Menu> | null => {
        return state.listOfGroupedMenus[key] ?? null
      },
      getListOfMenus: (state) => (key): Menu | null => {
        return state.listOfMenus[key] ?? null
      },
    },
    mutations: {
      listOfGroupedMenusData(state, payload: { key: string, menus: Array<MenuData> }) {
        state.listOfDataForGroupedMenus[payload.key] = payload.menus
      },
      listOfMenusData(state, payload: { key: string, menu: MenuData }) {
        state.listOfDataForMenus[payload.key] = payload.menu
      },
      listOfGroupedMenus(state, payload: { key: string, menus: Array<Menu> }) {
        state.listOfGroupedMenus[payload.key] = payload.menus
      },
      listOfMenus(state, payload: { key: string, menu: Menu }) {
        state.listOfMenus[payload.key] = payload.menu
      },
    },
    actions: {
      loadDataForMenus({getters, commit}, {templateTid, templateAreaTid, withItems}): Promise<Array<MenuData>> {
        let key = `${templateTid}_${templateAreaTid}_${withItems.toString()}`
        let menusData = getters['getDataForListOfGroupedMenus'](key)
        if (menusData) {
          return (new Promise<Array<MenuData>>((resolve) => resolve(menusData)))
        }
        return getMenusData(templateTid, templateAreaTid, withItems)
          .then(async data => {
            await commit('listOfGroupedMenusData', {key: key, menus: data})
            return data
          })
      },
      loadDataForMenu({getters, commit}, {menuTid, templateTid, templateAreaTid, withItems}): Promise<MenuData> {
        let key = `${menuTid}_${templateTid}_${templateAreaTid}_${withItems.toString()}`
        let menuData = getters['getDataForListOfMenus'](key)
        if (menuData) {
          return (new Promise<MenuData>((resolve) => resolve(menuData)))
        }
        return getMenuData(menuTid, templateTid, templateAreaTid, withItems)
          .then(async data => {
            await commit('listOfMenusData', {key: key, menu: data})
            return data
          })
      },
      getMenus({getters, commit, dispatch}, {templateTid, templateAreaTid, withItems}): Promise<Array<Menu>> {
        let menus: Array<Menu> = []
        return dispatch('loadDataForMenus', {templateTid, templateAreaTid, withItems}).then(async data => {
          data.forEach(menuData => {
            let menu: Menu = new Menu(menuData.tid, menuData.title);
            for (let itemData of buildTree(null, menuData.items)) {
              menu.addItem(new MenuItem(itemData))
            }
            menus.push(menu)
          })
          return menus
        })
      },

      getMenu({getters, commit, dispatch}, {menuTid, templateTid, templateAreaTid, withItems}): Promise<Menu> {
        return dispatch('loadDataForMenu', {
          menuTid: menuTid,
          templateTid: templateTid,
          templateAreaTid: templateAreaTid,
          withItems: withItems
        })
          .then(data => {
            return buildMenu(data)
          })
      },
    },
  }
}
