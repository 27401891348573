export enum GoalVendor {yandex = 'yandex', google = 'google'}

export interface GoalItemData {
  vendor: GoalVendor
  value: string
}

export interface GoalItemInterface {
  vendor(): GoalVendor

  value(): string
}

export default class GoalItem implements GoalItemInterface {
  private readonly data: GoalItemData

  public constructor(data: GoalItemData) {
    this.data = data

  }

  public vendor(): GoalVendor {
    return this.data.vendor
  }

  public value(): string {
    return this.data.value
  }
}
