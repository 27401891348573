<template>
  <SiteHeader class="tes-header"/>
  <main class="tes-body" id="_tes_content_">
    <slot/>
  </main>
  <SiteFooter class="tes-footer"/>
</template>

<script>
import SiteHeader from './default/SiteHeader.vue'
import SiteFooter from './default/SiteFooter.vue'

export default {
  name: "LayoutDefault",
  components: {
    SiteFooter,
    SiteHeader
  }
}
</script>
