<template>

  <header class="tes-header container">

    <transition name="toggle-left">
      <nav v-show="visibleSideMenu" class="tes-navigation-t2">

        <div>
          <div>
            <a href="tel:+78005006029">8 (800) 500-6029</a>
            <a  onclick="jivo_api.open({start:'call'});return false;">Оставить заявку</a>
          </div>
        </div>

        <slot name="slide_main_menu"></slot>

      </nav>
    </transition>

    <div class="tes-logo">
      <a href="/" title="Тион логотип" class="tes-logo__link">
        <Logo class="tes-logo__icon" title="Логотип группы компаний Тион" alt="Логотип группы компаний Тион"/>
      </a>
    </div>

    <Suspense>
      <template #default>
        <MainMenu class="tes-menu-main"/>
      </template>
      <template #fallback>
        <EllipsisLoader :loading="true" class="tes-loader__overlay_inner"/>
      </template>
    </Suspense>

    <div class="tes-phone-contacts">

      <a id="watsApp-btn" target="_blank" href="https://wa.me/79232414851"
         class="phone-contact tes-icon-wrapper tes-icon-wrapper_26x26"
         onclick="window.fbq('track', 'Contact', {content_name: 'WhatsApp'});" rel="noreferrer"
         title="Открыть WatsApp 7(923)241-48-51" aria-label="Открыть WatsApp 7(923)241-48-51">
        <WhatsAppIcon class="tes-icon26x26"/>
        <span class="phone-title not-for-anyone" aria-hidden="true">WatsApp ТИОН - 7(923)241-48-51</span>
      </a>

      <a class="tes-icon-wrapper mgo-number-13230 default phone-contact" href="tel:+78005006029"
         title="Позвонить 8(800)500-6029" aria-label="Позвонить 8(800)500-6029">
        <MobilePhoneIcon class="tes-icon16x26"/>
        <span class="phone-title" aria-hidden="true">8 (800) 500-6029</span>
      </a>

      <a class="tes-icon-wrapper phone-contact hotels" href="tel:+79039419007" v-show="false"
         title="Позвонить 7(903)941-90-07" aria-label="Позвонить 7(903)941-90-07">
        <MobilePhoneIcon class="tes-icon16x26"/>
        <span class="phone-title" aria-hidden="true">7 (903) 941-9007</span>
      </a>

    </div>

    <div class="tes-user-activity">

      <WhereBuy class="tes-where-buy-menu"/>

      <a class="tes-icon-wrapper tes-signin" title="Вход в Личный кабинет" href="/shop/account/">
        <PersonalCabinetIcon class="tes-icon29x26 tes-signin-icon"/>
      </a>

      <ShopCart/>

      <MainMenuButton/>
    </div>

  </header>

</template>

<script lang="ts">
import MainMenu from '~/components/menu/MainMenu.vue'
import WhereBuy from '~/components/WhereBuy.vue'
import ShopCart from '~/components/shop/ShopCart.vue'
import MainMenuButton from '~/components/menu/MainMenuButton.vue'

import Logo from '~/assets/logos/logo-tion-default.svg'
import PersonalCabinetIcon from '~/assets/icons/entrance.svg'
import WhatsAppIcon from '~/assets/social/whatsapp.svg'
import MobilePhoneRingIcon from '~/assets/icons/phone-ring.svg'
import MobilePhoneIcon from '~/assets/icons/phone.svg'
import {ref} from "vue";

export default {
  name: "SiteHeader",
  components: {
    MainMenuButton,
    ShopCart,
    WhereBuy,
    MainMenu,
    Logo,
    PersonalCabinetIcon,
    MobilePhoneRingIcon, MobilePhoneIcon, WhatsAppIcon
  },
  setup() {
    const visibleSideMenu = ref(false)
    const closeSideMenu = () => {
      visibleSideMenu.value = false
    }
    const openSideMenu = () => {
      visibleSideMenu.value = true
    }
    return {visibleSideMenu, closeSideMenu, openSideMenu, PersonalCabinetIcon}
  }
}
</script>

<style src="~/styles/_header_standard.scss"/>
