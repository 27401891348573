import {Router, RouteRecordName} from "vue-router";

export enum YaMetrikaLoaded {
  ON_SCROLL = 'scroll',
  ON_TOUCHSTART = 'touchstart',
  ON_MOUSEENTER = 'mouseenter',
  ON_CLICK = 'click',
  ON_DOM_CONTENT_LOADED = 'DOMContentLoaded'
}

export declare type YaMetrikaOptionsData = {
  accurateTrackBounce: undefined | boolean,
  clickmap: undefined | boolean,
  defer: undefined | boolean,
  ecommerce: undefined | boolean,
  params: undefined | Array<any>,
  userParams: undefined | object,
  trackHash: undefined | boolean,
  trackLinks: undefined | boolean,
  type: undefined | number,
  webvisor: undefined | boolean,
  triggerEvent: undefined | boolean
}

export declare type YaMetrikaConfigureData = {
  id: number | null
  options: YaMetrikaOptionsData
  activateLoadOn: undefined | null | Array<YaMetrikaLoaded>
  vueRouter: undefined | null | Router
  scriptSrc: undefined | string
  loadScript: undefined | boolean
  testMode: undefined | boolean
  debug: undefined | boolean
  ignoreRoutes: Array<RouteRecordName>
  skipSamePath: undefined | boolean
}

export const configure = {
  id: null,
  options: {
    accurateTrackBounce: true,
    clickmap: true,
    defer: false,
    ecommerce: false,
    params: [],
    userParams: {},
    trackHash: false,
    trackLinks: true,
    type: 0,
    webvisor: false,
    triggerEvent: false
  } as YaMetrikaOptionsData,
  activateLoadOn: ['scroll', 'touchstart', 'mouseenter', 'click', 'DOMContentLoaded'], // |
  vueRouter: null,
  scriptSrc: 'https://mc.yandex.ru/metrika/tag.js',
  loadScript: true,
  testMode: true,
  debug: false,
  ignoreRoutes: [],
  skipSamePath: true
} as YaMetrikaConfigureData

export interface YaMetrikaConfigureInterface {
  isDebug(): boolean
  isTestMode(): boolean
  yaId(): number | null
  yaOptions(): YaMetrikaOptionsData
  vueRouter(): Router | null
  ignoreRoutes(): Array<RouteRecordName>
  skipSamePath(): boolean
  scriptSrc(): string
  needLoadScript(): boolean
}

/**
 * https://yandex.ru/support/metrica/objects/method-reference.html
 */
export interface YaMetrikaInterface {
  /**
   * Добавить поддержку расширения файла. https://yandex.ru/support/metrica/objects/addfileextension.html
   * @param ext string | string[] - Расширение имени файла, заданное в виде строки или список расширений,
   * указанный в виде массива строк
   */
  addFileExtension(ext: string | Array<string>): void

  /**
   * Отправка информации о переходе по внешней ссылке. https://yandex.ru/support/metrica/objects/extlink.html
   * @param path string - URL страницы, на которую произошел переход
   * @param options object:
   *  callback [Function] - Callback-функция, вызываемая после отправки данных о загрузке файла
   *  ctx [Object] - Контекст, доступный в callback-функции по ключевому слову this
   *  params [Object] - Параметры визита
   *   order_price [Double] - Доход по цели. Вы можете указать цену как в валюте, так и в условных единицах.
   *   currency [String] - Используйте это поле, если хотите передать цену цели в валюте. Метрика распознает трехбуквенный код валюты по ISO 4217
   *   Если передается другая валюта, будут отправлены нулевые значения вместо валюты и суммы
   * title [String] - Заголовок текущей страницы
   */
  extLink(path: string, options: object): void

  /**
   * Отправка информации о загрузке файла https://yandex.ru/support/metrica/objects/file.html
   * @param path string - URL загруженного файла.
   * @param options object
   */
  file(path: string, options: object): void

  /**
   * Получение идентификатора посетителя, заданного Метрикой. https://yandex.ru/support/metrica/objects/get-client-id.html
   */
  getClientID(): string

  /**
   * Отправка вручную данных о просмотрах для AJAX- и Flash-сайтов.
   * @param path string
   * @param options object :
   *  callback [Function] - Callback-функция, вызываемая после отправки данных о просмотре
   *  ctx [Object] — Контекст, доступный в callback-функции по ключевому слову this
   *  params [Object]: - Параметры визита
   *    order_price [Double] - Доход по цели. Вы можете указать цену как в валюте, так и в условных единицах.
   *    currency [String] - Используйте это поле, если хотите передать цену цели в валюте. Метрика распознает трехбуквенный код валюты по ISO 4217.
   *    Если передается другая валюта, будут отправлены нулевые значения вместо валюты и суммы.
   *  referer [String] - URL с которого посетитель загрузил содержимое текущей страницы
   *  title [String] - Заголовок текущей страницы
   */
  hit(path: string, options: object): void

  /**
   * Передача информации о том, что визит пользователя не является отказом. https://yandex.ru/support/metrica/objects/notbounce.html
   * @param options object
   *  callback [Function] - Callback-функция, вызываемая после отправки данных о просмотре
   *  ctx [Object] - Контекст, доступный в callback-функции по ключевому слову this
   */
  notBounce(options: {callback: (...args: any[]) => void, ctx: object}): void

  /**
   * Дополнительный способ передачи пользовательских параметров в отчет Параметры визитов https://yandex.ru/support/metrica/objects/params-method.html
   * @param parameters
   */
  params(parameters: object | Array<object>): void

  /**
   * Достижение цели. https://yandex.ru/support/metrica/objects/reachgoal.html
   * @param target String - Идентификатор цели. Задается на странице редактирования счетчика при создании или изменении цели типа «JavaScript-событие».
   * @param params Object: - Параметры визита
   *  order_price [Double] - Доход по цели. Вы можете указать цену как в валюте, так и в условных единицах.
   *  currency [String] - Используйте это поле, если хотите передать цену цели в валюте. Метрика распознает трехбуквенный код валюты по ISO 4217.
   *  Если передается другая валюта, будут отправлены нулевые значения вместо валюты и суммы.
   * @param callback Function - Callback-функция, вызываемая после отправки данных о просмотре.
   * @param ctx Object — Контекст, доступный в callback-функции по ключевому слову this.
   */
  reachGoal(target: string, params: object | undefined, callback: (...args: any[]) => void | undefined, ctx: object | undefined): void

  /**
   * Передача идентификатора посетителя, заданного владельцем сайта. https://yandex.ru/support/metrica/objects/set-user-id.html
   * @param id string
   */
  setUserID(id: string): void

  /**
   * Способ передачи пользовательских параметров в отчет Параметры посетителей. https://yandex.ru/support/metrica/objects/user-params.html
   * @param parameters object - Параметры посетителей. {
   *     status: "Gold",
   *     child: 1,
   *     child_age: 13,
   *     UserID: 12345
   * }
   */
  userParams(parameters: object): void
}

let __YA_COUNTER_NUM__ = 0
export class YaMetrika implements YaMetrikaInterface, YaMetrikaConfigureInterface {
  'use strict'

  private readonly _configure: YaMetrikaConfigureData
  private metrika: YaMetrikaInterface | null = null
  private num: number

  constructor(options: YaMetrikaConfigureData) {
    if (!options.id) {
      throw new Error('[vue-ya-metrika] set Yandex Metrika tracking ID')
    }
    this._configure = JSON.parse(JSON.stringify(configure));
    Object.keys(options).forEach((key) => {
      if (typeof (options[key]) !== 'undefined') {
        this._configure[key] = options[key]
      }
    })
    this.num = ++__YA_COUNTER_NUM__
  }

  public isDebug(): boolean {
    return this._configure.debug??false
  }
  public isTestMode(): boolean {
    return this._configure.testMode??false
  }
  public yaId(): number | null {
    return this._configure.id
  }
  public yaOptions(): YaMetrikaOptionsData {
    return this._configure.options
  }
  public vueRouter(): Router | null {
    return this._configure.vueRouter??null
  }
  public ignoreRoutes(): Array<RouteRecordName> {
    return this._configure.ignoreRoutes??[]
  }
  public skipSamePath(): boolean {
    return this._configure.skipSamePath??false
  }
  public scriptSrc(): string {
    return this._configure.scriptSrc??'https://mc.yandex.ru/metrika/tag.js'
  }
  public needLoadScript(): boolean {
    return this._configure.loadScript??true
  }

  public addFileExtension(ext: string | Array<string>) {
    if (this.isDebug()) {console.log(`[vue-ya-metrika] ${this.yaId()} addFileExtension -> `, ext)}
    this.metrika?.addFileExtension(ext)
  }
  public extLink(path: string, options: object) {
    if (this.isDebug()) {console.log(`[vue-ya-metrika] ${this.yaId()} extLink: ${path} -> `, options)}
    this.metrika?.extLink(path, options)
  }
  public file(path: string, options: object) {
    if (this.isDebug()) {console.log(`[vue-ya-metrika] ${this.yaId()} file: ${path} -> `, options)}
    this.metrika?.file(path, options)
  }
  public hit(path: string, options: object): void {
    if (this.isDebug()) {
      console.log(`[vue-ya-metrika] ${this.yaId()} hit: ${path} -> `, options)
    }
    this.metrika?.hit(path, options)
  }
  public getClientID(): string {
    let id = this.metrika ? this.metrika.getClientID() : 'unknown'
    if (this.isDebug()) {
      console.log(`[vue-ya-metrika] ${this.yaId()} getClientID: ${id}`)
    }
    return id
  }
  public notBounce(options: {callback: (...args: any[]) => void, ctx: object}) {
    if (this.isDebug()) {console.log(`[vue-ya-metrika] ${this.yaId()} notBounce -> `, options)}
    this.metrika?.notBounce(options)
  }
  public params(parameters: object | Array<object>) {
    if (this.isDebug()) {console.log(`[vue-ya-metrika] ${this.yaId()} params -> `, parameters)}
    this.metrika?.params(parameters)
  }
  public reachGoal(target: string, params: object | undefined, callback: (...args: any[]) => void | undefined, ctx: object | undefined) {
    if (this.isDebug()) {console.log(`[vue-ya-metrika] ${this.yaId()} reachGoal: ${target} -> `, params, callback, ctx)}
    this.metrika?.reachGoal(target, params, callback, ctx)
  }
  public setUserID(id: string) {
    if (this.isDebug()) {console.log(`[vue-ya-metrika] ${this.yaId()} setUserID: ${id}`)}
    this.metrika?.setUserID(id)
  }
  public userParams(parameters: object) {
    if (this.isDebug()) {
      console.log(`[vue-ya-metrika] ${this.yaId()} userParams: -> `, parameters)
    }
    this.metrika?.userParams(parameters)
  }


  public init = () => {

    if (this.isTestMode()) {
      console.warn(`[vue-ya-metrika] ${this.yaId()} - ${this.num} Run in TEST MODE`)
    }

    if (this.isDebug()) {
      console.log(`[vue-ya-metrika] ${this.yaId()} - ${this.num} -> `, this._configure)
    }

    (function (m, e, i) {
      m[i] = m[i] || function () {(m[i].a = m[i].a || []).push(arguments)}
      m[i].l = (new Date()).getTime()
    })(window, document, "ym")

    if (navigator.userAgent.indexOf('YandexMetrika') > -1) {
      this.load()
      return
    }

    window.addEventListener('scroll', this.load, {passive: true})
    window.addEventListener('touchstart', this.load)
    document.addEventListener('mouseenter', this.load)
    document.addEventListener('click', this.load)
    document.addEventListener('DOMContentLoaded', this.loadWithTimeout)

  }

  private startTrackedVue = () => {
    if (this.isDebug()) {
      console.warn(`[vue-ya-metrika] ${this.yaId()} start Tracked Vue`)
    }

    let router = this.vueRouter()

    if (!router) {
      if (this.isDebug()) {
        console.warn(`[vue-ya-metrika] ${this.yaId()} Router is not passed, autotracking is disabled`)
      }
      return
    }

    router.afterEach((to, from) => {
      if (this.ignoreRoutes().indexOf(to.name as RouteRecordName) > -1) {
        return
      }
      if (this.skipSamePath() && to.path == from.path) {
        return
      }
      this.hit(to.path, {referer: from.path})
    })

  }

  private loaded: boolean = false
  private loading: boolean = false
  private timerId: any
  private load = (e: Event | undefined = undefined) => {

    if (this.isDebug()) {
      console.warn(`[vue-ya-metrika] ${this.yaId()} loading: ${this.loading}, loaded: ${this.loaded}`)
    }

    if (this.loading || this.loaded) {
      return;
    }
    this.loading = true

    if (this.timerId) {
      clearTimeout(this.timerId)
    }
    this.timerId = null

    if (this.isDebug()) {
      if (e && e.type) {
        console.log(`[vue-ya-metrika] ${this.yaId()} load on ${e.type}`);
      } else {
        console.log(`[vue-ya-metrika] ${this.yaId()} DOMContentLoaded`);
      }
    }

    if (this.isTestMode()) {
      this.successLoad()
    } else {
      var head: HTMLHeadElement = document.head || document.getElementsByTagName('head')[0]
      let scriptElement: HTMLScriptElement = document.createElement('script')

      // <!-- Yandex.Metrika counter -->
      // <script type="text/javascript" > (function(m,e,t,r,i,k,a){m[i]=m[i]||function(){(m[i].a=m[i].a||[]).push(arguments)}; m[i].l=1*new Date();k=e.createElement(t),a=e.getElementsByTagName(t)[0],k.async=true/*,k.src=r,a.parentNode.insertBefore(k,a)*/}) (window, document, "script", "https://mc.yandex.ru/metrika/tag.js", "ym"); /*ym(${this.yaID()}, "init", { clickmap:true, trackLinks:true, accurateTrackBounce:true, webvisor:true, trackHash:true, ecommerce:"DataLayer" });*/</script>
      // <noscript><div><img src="https://mc.yandex.ru/watch/${this.yaID()}" style="position:absolute; left:-9999px;" alt="" /></div></noscript>
      // <!-- /Yandex.Metrika counter -->

      scriptElement.async = true
      scriptElement.src = this.scriptSrc()
      scriptElement.onload = this.successLoad
      scriptElement.onerror = this.errorLoad
      head.appendChild(scriptElement)
    }

    window.removeEventListener('scroll', this.load)
    window.removeEventListener('touchstart', this.load)
    document.removeEventListener('mouseenter', this.load)
    document.removeEventListener('click', this.load)
    document.removeEventListener('DOMContentLoaded', this.loadWithTimeout)
  }

  private loadWithTimeout = () => {
    this.timerId = setTimeout(() => this.load(), 2000);
  }

  private successLoad = (): void => {
    if (this.isDebug()) {
      console.log(`[vue-ya-metrika] ${this.yaId()} js file load success`)
    }
    this.loaded = true
    this.loading = false
    document.dispatchEvent(new Event('VueYaMetrika::success'))
    if (!this.isTestMode()) {
      if (window['Ya']) {
        this.metrika = new window['Ya'].Metrika2({
          id: this.yaId(),
          ...this.yaOptions()
        })
      } else if (this.isDebug()) {
        console.warn(`[vue-ya-metrika] ${this.yaId()} type of Ya undefined`)
      }
    }
    this.startTrackedVue()
    window[`yaCounter${this.yaId()}`] = !this.isTestMode() ? this.metrika : null
  }

  private errorLoad = (): void => {
    if (this.isDebug()) {
      console.log(`[vue-ya-metrika] ${this.yaId()} js file load failure`)
    }
    document.dispatchEvent(new Event('VueYaMetrika::failure'))
    this.loaded = false
    this.loading = false
  }

}

const VueYaMetrika = {
  install: function (Vue, options = {} as YaMetrikaConfigureData) {
    let yaM = new YaMetrika(options)
    yaM.init()
    return Vue.config.globalProperties.$metrika = yaM
  }
}

export default VueYaMetrika
