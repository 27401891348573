import {getToken} from './states/user'

export default function clientHeaders(): Object {
  return {
    'Authorization': 'Bearer ' + getToken(),
    //'x-access-token': getToken(),
    'Accept-Language': 'ru',
    'Accept-Tion-Context-Site': 'tion',
    'Accept-Tion-Context-Market': 'russian',
    //'X-XSRF-TOKEN': getCookieValue('XSRF-TOKEN')
  }
}
