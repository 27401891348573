import {reactive, readonly} from 'vue'

export declare type Loader = {
  start(): void
  stop(): void
  state: { loading: boolean }
}

export const loaderStateSymbol = Symbol('loader');
export const createLoaderState = (): Loader => {
  const state = reactive({loading: false})
  const start = () => state.loading = true
  const stop = () => state.loading = false
  return {start, stop, state: readonly(state)}
}
