<template>
  <nav itemscope itemtype="http://schema.org/SiteNavigationElement">
    <ul :class="['tes-menu', `tes-menu_${template}`]" @mouseleave="leaveAll($event)">
      <li v-for="(item, index) in menu.items()" v-bind:key="item.id()"
          @mouseenter="enter($event, item)"
          :class="[
            `tes-menu-item`,
            `tes-menu-item_${item.template()}`,
            `tes-menu-item_top`,
            `tes-menu-item_number-${index}`,
            item.css(),
            isFocusedOn(item) ? `tes-menu-item_active` : ``]">
        <a :href="item.link().value()" class="tes-menu-item-link tes-menu-item-link_top"
           itemprop="url"
        >{{ item.title() }}
          <template
            v-if="item.icon() && item.icon().id()">&nbsp;<IconView :icon="item.icon()"/>
          </template>
        </a>

        <!-- transition name="fade" -->
        <template v-if="item.hasItems()">
          <MainSubMenu :show="isFocusedOn(item)" :level="1" :items="item.items()" :layout="item.template()"/>
        </template>
        <!-- /transition -->

      </li>
    </ul>
  </nav>
</template>

<script lang="ts">
import MenuItem from '../../objects/menu/MenuItem'
import Menu from '../../objects/menu/Menu'
import MainSubMenu from './MainSubMenu.vue'
import IconView from '../icon/IconView.vue'
import {ref} from 'vue'
import {useStore} from 'vuex'

export default {
  name: 'MainMenu',
  components: {IconView, MainSubMenu},
  props: {
    template: {
      type: String,
      default: 'standard'
    },
  },
  async setup(props) {
    const store = useStore()

    let timer: number | null = null;
    const opened = ref({level: 0, path: null} as { level: number, path: string | null })
    const enter = (event: Event, item: MenuItem) => {
      opened.value.path = item.link().value()
      if (timer != null) {
        window.clearTimeout(timer)
        timer = null
      }
    }
    const leaveAll = (event: Event) => {
      timer = window.setTimeout(() => {
        opened.value.path = null
      }, 300)
    }
    const isFocusedOn = (item: MenuItem) => opened.value.path === item.link().value()
    const menu = ref(new Menu('main', 'Основное'));
    await store.dispatch('menus/getMenu',  {menuTid: 'main', templateTid: props.template, templateAreaTid:'header', withItems: true}, { root: true })
      .then(data => {
        menu.value = data
      })
      .catch(e => console.log(e))

    return {menu, opened, enter, leaveAll, isFocusedOn}
  }
};
</script>
