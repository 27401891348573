<template>
  <span :class="`tes-icon-view tes-icon-view_${icon.type()}`"
       v-if="icon && icon.id()">
    <img :class="['tes-icon-view__image', `${iconClass}`]"
        :width="icon.dimensions().width()"
        :height="icon.dimensions().height()" :src="icon.url()??''" alt=""
         v-if="icon.type() === 'storage'"/>
    <!-- font-awesome-icon :icon="icon.prepareForFontawesomeCss()" :style="icon.style()" v-else-if="icon.type() === 'css'"/ -->
    <i :class="`${icon.prepareCss()}`" v-else-if="icon.type() === 'css'"></i>
    <span :style="icon.prepareFullStyle()" v-else-if="icon.type() === 'style'"></span>
    <span v-else></span>
  </span>
</template>

<script lang="ts">

import {PropType} from "vue";
import {IconInterface} from "../../objects/Icon";

export default {
  name: 'IconView',
  props: {
    icon: {
      type: Object as PropType<IconInterface>,
      required: true,
    },
    iconClass: {
      type: String,
      default: null
    }
  }
}
</script>
