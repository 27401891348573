<template>
  <button class="tes-toggle-menu" aria-label="Скрыть показать основное меню"
          title="Скрыть показать основное меню">
    <BarsIcon/>
    <span class="not-for-anyone" aria-hidden="true">Скрыть показать основное меню</span>
  </button>
</template>

<script lang="ts">
import BarsIcon from '@assets/icons/bars.svg'

export default {
  name: "MainMenuButton",
  components: {BarsIcon}
}
</script>
