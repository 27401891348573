<template>

  <div class="tes-cookies-agreements" v-show="visible">

    <div class="tes-cookies-agreements__content container">
      <button type="button" class="tes-cookies-agreements__close" @click="disagree" title="Закрыть панель соглашения">
        <CloseIcon class="tes-cookies-agreements__close-icon" width="40px" height="40px"/>
        <span class="not-for-anyone" aria-hidden="true">Закрыть панель соглашения(сбор кук)</span>
      </button>
      <div class="tes-cookies-agreements__content-wrapper">
        Тион собирает информацию об использовании этого сайта, в частности размещает файлы cookie в браузерах
        посетителей. <a href="/doc/general/soglasie_personal_data.pdf?v=2.112" target="_blank">Подробнее</a>
      </div>
      <button type="button" class="tes-cookies-agreements__button tes-cookies-agreements__button_agree" @click="agree">
        OK
      </button>
    </div>

  </div>

</template>

<script type="ts">
import CloseIcon from '~/assets/icons/close-v2.svg'
import {onMounted, ref} from 'vue'
import {useCookies} from 'vue3-cookies'

export const __AGREE_COOKIE_KEY__ = 'readCookie'

export default {
  name: 'SiteCookies',
  components: {
    CloseIcon
  },
  setup() {

    const {cookies} = useCookies();
    const visible = ref(true);

    const setCookies = () => {
      let date = new Date()
      date.setTime(date.getTime() + (24 * 60 * 60 * 1000))
      cookies.set(__AGREE_COOKIE_KEY__, 2, date)
    }

    const isSetCookies = () => {
      let value = parseInt(cookies.get(__AGREE_COOKIE_KEY__))
      return !isNaN(value) && value === 2
    }

    const agree = () => {
      setCookies()
      visible.value = false
    }

    const disagree = () => {
      setCookies()
      visible.value = false
    }

    onMounted(() => {
      visible.value = !isSetCookies()
    })

    return {visible, agree, disagree}
  }
}
</script>

<style lang="scss" scoped>
.tes-cookies-agreements {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: #ededed;
  min-height: 48px;
  z-index: 9999999999;
  opacity: 0.9;
  font-size: 11px;
  color: #575756;

  @media (max-width: 767px) {
    padding: 5px;
    min-height: 60px;
  }

  &__content {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    min-height: 48px;

    @media (max-width: 767px) {
      min-height: 60px;
    }

    &-wrapper {
      text-align: center;
      font-size: 11px;
    }

    a {
      color: #575756;
      margin-left: 15px;
      text-decoration: underline;
      font-size: 11px;
    }
  }

  &__button {
    outline: none;
    display: block;
    padding: 4px;
    font-size: 11px;
    text-decoration: none;
    cursor: pointer;
    border-width: 1px;
    border-radius: 3px;
    border-style: solid;
    border-color: #575756;
    color: #000;
    background-color: transparent;

    &:focus {
      outline: none;
      border: none;
    }

    &_agree {
      margin-left: 10px;
      height: 30px;
      min-height: 30px;
      width: 30px;
      min-width: 30px;
      border-radius: 4px;
      line-height: 0;
      padding: 0;
      text-align: center;
    }
  }

  &__close {
    outline: none;
    display: block;
    padding: 0;
    margin-right: 10px;
    cursor: pointer;
    border: none;
    background-color: transparent;
    color: #575756;

    &-icon {
      width: 30px;
      height: 30px;
    }

    &:focus {
      outline: none;
      border: none;
    }
  }

  &.visible {
    display: block;
  }

}
</style>
