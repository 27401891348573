import App from './App.vue'
import {createApp, createSSRApp} from 'vue'
import {createHead} from '@vueuse/head'
import {createRouter} from './router'
import {loaderStateSymbol, createLoaderState} from './states/loader'
import {pageHeadersStateSymbol, createPageHeadersState} from "./states/page-heads";
import {createStore} from './store'
import {createGtm} from '@gtm-support/vue-gtm'
import VueYaMetrika from './plugins/vue/ya-metrika'

import LazyLoadPicture from './components/lazy/LazyLoadPicture.vue'
import ObserverComponent from './components/lazy/ObserverComponent.vue'
import EllipsisLoader from './components/loader/EllipsisLoader.vue'

export function createSite() {
  const app = import.meta.env.SSR ? createSSRApp(App) : createApp(App)
  const router = createRouter()
  const head = createHead()
  const store = createStore()


  app
    .provide(loaderStateSymbol, createLoaderState())
    .provide(pageHeadersStateSymbol, createPageHeadersState())
    .use(head)
    .use(router)
    .use(store)
    .component('LazyLoadPicture', LazyLoadPicture)
    .component('EllipsisLoader', EllipsisLoader)
    .component('ObserverComponent', ObserverComponent)

  if (!import.meta.env.SSR) {
    app
      .use(
        createGtm({
          id: 'GTM-WTZ24XP',
          defer: true, // Script can be set to `defer` to speed up page load at the cost of less accurate results (in case visitor leaves before script is loaded, which is unlikely but possible). Defaults to false, so the script is loaded `async` by default
          compatibility: true, // Will add `async` and `defer` to the script tag to not block requests for old browsers that do not support `async`
          nonce: '56h6d0f26d', // Will add `nonce` to the script tag
          enabled: true, // defaults to true. Plugin can be disabled by setting this to false for Ex: enabled: !!GDPR_Cookie (optional)
          debug: !import.meta.env.PROD, // Whether or not display console logs debugs (optional)
          loadScript: true, // Whether or not to load the GTM Script (Helpful if you are including GTM manually, but need the dataLayer functionality in your components) (optional)
          vueRouter: router, // Pass the router instance to automatically sync with router (optional)
          //trackOnNextTick: false // Whether or not call trackView in Vue.nextTick
        })
      )
      .use(VueYaMetrika, {
        id: 1602419,
        nonce: '56h6d0f26e',
        vueRouter: router,
        testMode: !import.meta.env.PROD,
        debug: !import.meta.env.PROD,
        options: {
          clickmap: true,
          trackLinks: true,
          accurateTrackBounce: true,
          webvisor: false,
          trackHash: true,
          ecommerce: "DataLayer"
        },
        ignoreRoutes: [],
        skipSamePath: false
      })
  }

  /*
  app.config.errorHandler = (err, instance, info) => {
    // report error to tracking services
  }
  */

  return {app, head, router, store}
}
