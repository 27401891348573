export interface PostThesisData {
  id: number | null
  tid: string
  title: string
  parent_id: number | null
  sort_num: number
}

export interface PostThesisInterface {
  id(): number | null

  anchor(): string

  title(): string

  hasTheses(): boolean

  theses(): Array<PostThesisInterface>

  addThesis(thesis: PostThesisInterface): void

  sortNum(): number
}

export default class PostThesis implements PostThesisInterface {
  private data: PostThesisData
  private readonly _theses: Array<PostThesisInterface>

  public constructor(data: PostThesisData) {
    this.data = data
    this._theses = []
  }

  id(): number | null {
    return this.data.id
  }

  anchor(): string {
    return this.data.tid
  }

  title(): string {
    return this.data.title
  }

  hasTheses(): boolean {
    return this._theses.length > 0
  }

  theses(): Array<PostThesisInterface> {
    return this._theses
  }

  addThesis(thesis: PostThesisInterface): void {
    this._theses.push(thesis)
  }

  sortNum(): number {
    return this.data.sort_num
  }
}
