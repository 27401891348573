import {
  createMemoryHistory,
  createRouter as _createRouter,
  createWebHistory,
  Router,
  RouteRecordRaw,
  RouterOptions
} from 'vue-router'
import {layoutBlog, LayoutInterface} from './objects/Layout'

const routs: RouteRecordRaw[] = [
  {
    name: 'Home',
    path: '/',
    component: () => import('./pages/main/index.vue'),
  } as RouteRecordRaw,
  {
    name: 'About',
    path: '/about/',
    component: () => import('./pages/about/index.vue')
  } as RouteRecordRaw,
  {
    name: 'Blog',
    path: '/blog/',
    component: () => import('./pages/blog/index.vue'),
    meta: {
      layout: layoutBlog as LayoutInterface
    }
  } as RouteRecordRaw,
  {
    name: 'BlogList',
    path: '/blog/page/:pageNum(\\d+)/',
    component: () => import('./pages/blog/list.vue'),
    props: true,
    meta: {
      layout: layoutBlog as LayoutInterface
    }
  } as RouteRecordRaw,
  {
    name: 'BlogTag',
    path: '/tag/:tid/',
    alias: ['/novosti/:tid/'],
    component: () => import('./pages/blog/list.vue'),
    props: true,
    meta: {
      layout: layoutBlog as LayoutInterface
    }
  } as RouteRecordRaw,
  {
    name: 'BlogTagPage',
    path: '/tag/:tid/page/:pageNum(\\d+)/',
    alias: ['/novosti/:tid/page/:pageNum(\\d+)/'],
    component: () => import('./pages/blog/list.vue'),
    props: true,
    meta: {
      layout: layoutBlog as LayoutInterface
    }
  } as RouteRecordRaw,
  {
    name: 'BlogPost',
    path: '/blog/:tid/',
    component: () => import('./pages/blog/post.vue'),
    props: true,
    meta: {
      layout: layoutBlog as LayoutInterface
    }
  } as RouteRecordRaw,
  {
    name: 'SiteSearch',
    path: '/search/:search/',
    component: () => import('./pages/blog/list.vue'),
    meta: {
      layout: layoutBlog as LayoutInterface
    },
    props: true,
  } as RouteRecordRaw,
  {
    name: 'SiteSearchPage',
    path: '/search/:search/page/:pageNum(\\d+)/',
    component: () => import('./pages/blog/list.vue'),
    meta: {
      layout: layoutBlog as LayoutInterface
    },
    props: true,
  } as RouteRecordRaw,
  {
    path: '/50:number([0|1|2|3|4|5])/',
    name: 'ServerError',
    component: () => import('./pages/5xx.vue'),
    props: true
  } as RouteRecordRaw,

  {
    path: '/ventilyaciya/:path(.*)',
    component: () => import('./pages/simple.vue'),
    props: route => ({ path: route.path })
  } as RouteRecordRaw,
  {
    path: '/ochistiteli-vozduha/',
    component: () => import('./pages/simple.vue'),
    props: route => ({ path: route.path })
  } as RouteRecordRaw,
  {
    path: '/:pathMatch(.*)*',
    name: 'NotFound',
    component: () => import('./pages/404.vue'),
    props: true,
    meta: {
      requiresAuth: false
    }
  } as RouteRecordRaw,
]

export function createRouter(): Router {
  /*
  router.beforeEach((to) => {
    console.log('_________________ / router.beforeEach / _________________')
  })

  router.beforeResolve((to, from, next) => {
    console.log('_________________ / router.beforeResolve / _________________')
    if (to.query && to.query.s) {
      next(`/search/${to.query.s}`)
    } else {
      next()
    }
  })
  */

  return _createRouter({
    history: import.meta.env.SSR ? createMemoryHistory() : createWebHistory(),
    routes: routs,
    //strict: true,
    //sensitive:false,
    scrollBehavior(to) {
      if (to.hash === '') {
        return {
          left: 0,
          top: 0
        }
      }
      return null
    }
  } as RouterOptions);
}
