import {createStore as _createStore} from 'vuex'
import {createLayoutStore} from './states/layout'
import {createListEntitiesStore} from './states/list-entity'
import {createMenusStore} from './states/menu'
import {createPagesStore} from './states/page'
import {createPostsStore} from './states/post'
import {createPostsTagsStore} from './states/post-tag'
import {createCommentsStore} from './states/comment'

export function createStore() {
  return _createStore({
    strict: import.meta.env.NODE_ENV === 'localhost',
    modules: {
      layout: createLayoutStore(),
      listEntities: createListEntitiesStore(),
      menus: createMenusStore(),
      pages: createPagesStore(),
      posts: createPostsStore(),
      postsTags: createPostsTagsStore(),
      comments: createCommentsStore(),
    },
  })
}
