<template>
  <div :class="['tes-loader__line', loading ? 'tes-loader__line_action' : '']">
    <div class="tes-loader__line-track" v-if="loading"></div>
  </div>
</template>

<script lang="ts">
export default {
  name: 'LineLoader',
  props: {
    loading: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="scss">

.tes-loader__line {
  --main-color: #111;
  --loader-color: #9c3;
  --back-color: #A5D6A7;
  --time: 3s;
  --size: 3px;

  background: transparent;
  overflow: hidden;
  width: 100%;
  height: var(--size);
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: flex-start;
  z-index: 1;

  &_action {
    background-color: var(--main-color);
  }
}

.tes-loader__line-track {
  height: var(--size);
  width: 100%;
  background: var(--back-color);

  &:before {
    content: '';
    display: block;
    background-color: var(--loader-color);
    height: var(--size);
    width: 0;
    animation: getWidth var(--time) ease-in infinite;
  }
}

@keyframes getWidth {
  100% {
    width: 100%;
  }
}
</style>
