<style lang="scss" scoped>
.social-panel {
  margin-top: 28px;
  margin-bottom: 20px;

  &__link {
    display: inline-block;
    text-align: center;
    padding-left: 5px;

    &:hover {
      color: #686868 !important;
    }

    &:first-child {
      padding-left: 0;
    }
  }

  &__icon {
    display: -ms-inline-flexbox;
    display: inline-flex;
    width: 29px;
    height: 29px;
    margin-right: 6px;

    &-facebook {
    }

    &-vkontakte {
    }

    &-instagram {
    }

    &-youtube {
    }

    &-telegram {
    }
  }

}
</style>

<template>
  <div class="social-panel">
    <a href="https://t.me/tion_official" class="social-panel__link"
       target="_blank" rel="noreferrer" title="ссылка на официальный телеграм канал">
      <TelegramIcon alt="telegram" class="social-panel__icon social-panel__icon-telegram"/>
      <span class="not-for-anyone" aria-hidden="true">официальный телеграм канал</span>
    </a>
    <a href="//vk.com/tion.official" class="social-panel__link"
       target="_blank" rel="noreferrer" title="ссылка на официальную группу vkontakte">
      <VkIcon alt="vkontakte" class="social-panel__icon social-panel__icon-vkontakte"/>
      <span class="not-for-anyone" aria-hidden="true">официальная группа vkontakte</span>
    </a>
    <a href="//www.youtube.com/user/tioninfo" class="social-panel__link"
       target="_blank" rel="noreferrer" title="ссылка на официальный канал на youtube">
      <YoutubeIcon alt="Youtube" class="social-panel__icon social-panel__icon-youtube"/>
      <span class="not-for-anyone" aria-hidden="true">официальный канал на youtube</span>
    </a>
  </div>
</template>

<script lang="ts">
import TelegramIcon from '~/assets/social/v3/icon-soc-tg-color.svg'
import VkIcon from '~/assets/social/v3/icon-soc-vk-color.svg'
import YoutubeIcon from '~/assets/social/v3/icon-soc-yt-color.svg'

export default {
  name: 'SiteSocialColor',
  components: {TelegramIcon, VkIcon, YoutubeIcon}
}
</script>
