import {HeadData, ItemAttrs, PageHeadData} from '../objects/Page'
import {PaginationInterface} from '../objects/Paginator'
import {readonly, Ref, ref} from "vue";
import {merge} from "../objects/utils";

export const SiteMainDomainURI = 'https://tion.ru/'

export const defaultRequiredHeads = {
  charset: 'UTF-8',
  type: 'standard',
  language_tid: 'ru',
  link: [
    {key: 'alternate_feed', rel: 'alternate', href: `${SiteMainDomainURI}feed/`, title: 'Тион &raquo; Лента'},
    {
      key: 'alternate_feed_comments',
      rel: 'alternate',
      href: `${SiteMainDomainURI}comments/feed/`,
      title: 'Тион &raquo; Лента комментариев'
    }
  ],
  meta: [
    //{vmid: 'Content-Security-Policy', name: 'Content-Security-Policy', content: 'default-src=self'},
    {key: 'viewport', name: 'viewport', content: 'width=device-width, initial-scale=1.0, shrink-to-fit=no'},
    //{vmid: 'HandheldFriendly', name: 'HandheldFriendly', content: ''},
    //{vmid: 'MobileOptimized', name: 'MobileOptimized', content: 'width'},
    //{vmid: 'apple-mobile-web-app-capable', name: 'apple-mobile-web-app-capable', content: 'yes'},
    {key: 'theme-color', name: 'theme-color', content: '#30CAFF'},
    // domain verifications
    {
      key: 'facebook-domain-verification',
      name: 'facebook-domain-verification',
      content: 'ofjt6wz2d0404hllt7nafwi3bq2olm'
    },
  ],
}

export const defaultPage404Heads: PageHeadData = {
  type: 'standard',
  language_tid: 'ru',
  title: 'Страница не найдена - Тион',
  description: '',
  /*canonical: {href: `${SiteMainDomainURI}404/`},*/
  og: {
    type: 'website',
    locale: 'ru-RU',
    site_name: 'Тион',
    image: '/assets/pages/404/404-28-08.jpg',
  },
  twitter: {
    card: 'summary_large_image',
    image: '/assets/pages/404/404-28-08.jpg',
  },
  link: [
    {key: 'alternate_ru', rel: 'alternate', href: `${SiteMainDomainURI}`, hreflang: 'ru-ru'},
    {key: 'alternate_kz', rel: 'alternate', href: 'https://tion.com.kz/', hreflang: 'kk-kz'},
  ],
  meta: [],
}

export const defaultPage5xxHeads: PageHeadData = {
  type: 'standard',
  language_tid: 'ru',
  title: 'Ошибка на стороне сервера - Тион',
  description: '',
  /*canonical: {href: `${SiteMainDomainURI}503/`},*/
  og: {
    type: 'website',
    locale: 'ru-RU',
    site_name: 'Тион',
    image: '/assets/logos/og-main.jpg',
  },
  twitter: {
    card: 'summary_large_image',
    image: '/assets/logos/logo.jpg',
  },
  link: [
    {key: 'alternate_ru', rel: 'alternate', href: `${SiteMainDomainURI}`, hreflang: 'ru-ru'},
    {key: 'alternate_kz', rel: 'alternate', href: 'https://tion.com.kz/', hreflang: 'kk-kz'},
  ],
  meta: [],
}

export const defaultPageHeads: PageHeadData = {
  type: 'standard',
  language_tid: 'ru',
  title: 'Официальный сайт Тион. Интернет-магазин бризеров',
  description: 'Официальный сайт группы компаний Тион. Мы создаем продукты и гаджеты для вентиляции, обеззараживания и очистки воздуха квартир и офисов.',
  canonical: {href: `${SiteMainDomainURI}`},
  og: {
    type: 'website',
    locale: 'ru-RU',
    site_name: 'Тион',
    image: '/assets/logos/og-main.jpg',
  },
  twitter: {
    card: 'summary_large_image',
    image: '/assets/logos/logo.jpg',
  },
  link: [
    {key: 'alternate_ru', rel: 'alternate', href: `${SiteMainDomainURI}`, hreflang: 'ru-ru'},
    {key: 'alternate_kz', rel: 'alternate', href: 'https://tion.com.kz/', hreflang: 'kk-kz'},
  ],
  meta: [],
}

export const defaultPageBlogHeads: PageHeadData = {
  type: 'standard',
  language_tid: 'ru',
  title: 'Блог Тион',
  description: 'Официальный блог Тион - полезные статьи о микроклимате, экологии, чистом воздухе и здоровье.',
  keywords: 'блог Тион',
  canonical: {href: `${SiteMainDomainURI}blog/`},
  og: {
    type: 'website',
    locale: 'ru-RU',
    site_name: 'Тион',
    image: '/assets/logos/og-main.jpg',
  },
  twitter: {
    card: 'summary_large_image',
    image: '/assets/logos/logo.jpg',
  },
  link: [],
  meta: [],
}

export const defaultPageBlogTagHeads: PageHeadData = {
  type: 'standard',
  language_tid: 'ru',
  title: '#${tag.name} - подборка статей на tion.ru',
  description: 'Подборка статей по тегу #${tag.name} на сайте tion.ru: информативные и познавательные тексты с экспертным мнением',
  canonical: {href: SiteMainDomainURI + 'tag/${tag.tid}/'},
  next_prev: true,
  og: {
    type: 'website',
    locale: 'ru-RU',
    site_name: 'Тион',
    image: '/assets/logos/og-main.jpg',
  },
  twitter: {
    card: 'summary_large_image',
    image: '/assets/logos/logo.jpg',
  },
  link: [],
  meta: [],
}

export const prepareHead = (
  heads: Array<PageHeadData>,
  pagination?: PaginationInterface,
  replace?: { [k: string]: string },
  options?: { [k: string]: any }
): HeadData => {
  const head: PageHeadData = merge(JSON.parse(JSON.stringify(defaultRequiredHeads)), JSON.parse(JSON.stringify(heads[0])));

  replace = replace ?? {}
  if (typeof pagination !== 'undefined') {
    replace['${page.nextNum}'] = pagination.nextPage() ? pagination.nextPage().toString() : ''
    replace['${page.prevNum}'] = pagination.prevPage() ? pagination.prevPage().toString() : ''
  }

  for (let k in replace) {
    if (typeof replace[k] !== 'undefined') {
      head.title = head.title.replace(k, replace[k])
      head.description = head.description.replace(k, replace[k])
      if (head.canonical && head.canonical.href) {
        head.canonical.href = head.canonical.href.replace(k, replace[k])
      }
    }
  }

  const title = head.title + (
    (typeof pagination !== 'undefined' && pagination.currentPage() > 0)
      ? (
        ` | Страница ${pagination.currentPage()}` + (pagination.totalPages() ? ` из ${pagination.totalPages()}` : '')
      )
      : ''
  )

  const links = {}
  if (typeof head.link === 'undefined' || !head.link) {
    head.link = []
  }
  for (let k in head.link) {
    let l = head.link[k]
    if (typeof l !== 'undefined' && l) {
      links[typeof l['key'] !== 'undefined' ? l['key'] : k] = l
    }
  }

  // TODO
  /*
  if (!head.canonical) {
    head.canonical = {href: }
  }
  */

  if (head.canonical) {

    head.canonical['key'] = 'canonical'
    head.canonical['rel'] = 'canonical'

    if (typeof pagination !== 'undefined') {
      let isTag = (head.canonical.href.indexOf('/tag/') > -1);

      if (!pagination.isLastPage()) {
        links['next'] = {'href': head.canonical.href + `page/${pagination.nextPage()}/`, 'rel': 'next'}
      }

      if (!pagination.isFirstPage()) {
        if (pagination.currentPage() === 2 && isTag) {
          links['prev'] = {'href': head.canonical.href, 'rel': 'prev'}
        } else {
          links['prev'] = {'href': head.canonical.href + `page/${pagination.prevPage()}/`, 'rel': 'prev'}
        }
      } else if (!isTag) {
        links['prev'] = {'href': head.canonical.href, 'rel': 'prev'}
      }

      if (
        (!isTag && pagination.currentPage())
        ||
        (isTag && pagination.currentPage() > 1)
      ) {
        head.canonical.href += `page/${pagination.currentPage()}/`
      }
    }
    links['canonical'] = head.canonical
  }

  const meta: { [k: string]: ItemAttrs } = {}
  meta['charset'] = {key: 'charset', charset: head.charset ?? 'UTF-8'}
  for (let k in head.meta) {
    if (typeof head.meta[k] !== 'undefined' && head.meta[k]) {
      meta[head.meta[k].key ?? head.meta[k].name] = head.meta[k];
    }
  }
  meta['description'] = {key: 'description', name: 'description', content: head.description ?? title}

  if (head.keywords) {
    meta['keywords'] = {key: 'keywords', name: 'keywords', content: head.keywords}
  }

  if (head.robots) {
    meta['robots'] = {key: 'robots', name: 'robots', content: head.robots}
  }

  meta[`og-title`] = {key: `og-title`, property: `og:title`, content: title}
  meta[`og-description`] = {key: `og-description`, property: `og:description`, content: head.description ?? ''}
  for (let k in head.og) {
    if (typeof head.og[k] !== 'undefined' && head.og[k]) {
      meta[`og-${k}`] = {key: `og-${k}`, property: `og:${k}`, content: head.og[k]}
    }
  }
  if (!meta[`og-url`] && head.canonical) {
    meta[`og-url`] = {key: 'og-url', property: 'og:url', content: head.canonical.href??''}
  }
  if (!meta[`og-type`]) {
    meta[`og-type`] = {key: 'og-type', property: 'og:type', content: 'website'}
  }

  meta[`twitter-title`] = {key: `twitter-title`, name: `twitter:title`, content: title}
  meta[`twitter-description`] = {key: `twitter-description`, name: `twitter:description`, content: head.description??''}
  for (let k in head.twitter) {
    if (typeof head.twitter[k] !== 'undefined' && head.twitter[k]) {
      meta[`twitter-${k}`] = {key: `twitter-${k}`, name: `twitter:${k}`, content: head.twitter[k]??''}
    }
  }

  return {
    htmlAttrs: {lang: head.language_tid/*, amp: true*/},
    meta: Object.values(meta),
    title: title,
    link: Object.values(links)
  } as HeadData
}

export const pageHeadersStateSymbol = Symbol('page_heads')

export declare type PageHeadsStore = {
  getHead(): HeadData
  updateHead(HeadData): void
  state: Readonly<Ref<any>>
}

export const createPageHeadersState = (): PageHeadsStore => {
  const state = ref({} as HeadData)
  const updateHead = (head: HeadData): void => {
    state.value = head
  }
  const getHead = (): HeadData => {
    return state.value
  }
  return {getHead, updateHead, state: readonly(state)}
}
