import {ListEntityData} from '../objects/list-entity/ListEntity'
import {getLisEntityDataTid} from '../services/fetch/list-entity'
import {generateKeyForObject} from "../objects/utils";

export function createListEntitiesStore() {
  return {
    namespaced: true,
    state: {
      listOfGroupedListEntities: {} as { [key: string]: ListEntityData },
    },
    mutations: {
      listOfGroupedListEntitiesData(state, payload: { key: string, entities: ListEntityData }) {
        state.listOfGroupedListEntities[payload.key] = payload.entities
      },
    },
    getters: {
      getDataForListOfGroupedListEntities: (state) => (key): ListEntityData | null => {
        return state.listOfGroupedListEntities[key] ?? null
      },
    },
    actions: {
      loadDataForListEntity({getters, commit}, {tid, props}): Promise<ListEntityData> {
        let key = generateKeyForObject({props}, tid)
        let listEntitiesData = getters['getDataForListOfGroupedListEntities'](key)
        if (listEntitiesData) {
          return (new Promise<ListEntityData>((resolve) => resolve(listEntitiesData)))
        }
        return getLisEntityDataTid(tid, props)
            .then(async data => {
              await commit('listOfGroupedListEntitiesData', { key: key, entities: data })
              return data
            })
      }
    },
  }
}
