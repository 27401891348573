import clientHeaders from '../../client.headers'
import {ListEntityData} from '../../objects/list-entity/ListEntity'
import {prepareQuery} from "../../objects/utils";
import fetch from 'cross-fetch';

export function getLisEntityDataTid(tid: number | string, props?: object | null): Promise<ListEntityData> {
  let headers = clientHeaders()
  headers['X-Requested-With'] = 'XMLHttpRequest'
  headers['Accept'] = 'application/json'
  return fetch(
    `${import.meta.env.VITE_APP_SERVER_API_URL}/v2/list-entities/${tid}` + (props ? `?${prepareQuery(props)}` : ''),
    {
      headers: headers as Headers
    }
  )
    .then(
      response => {
        return response.json().then(data => {
          if (response.ok) {
            return data.data;
          } else {
            return Promise.reject({status: response.status, data});
          }
        });
      }
    )
}
