<template>
  <div class="widget_shopping_cart">

    <a class="cart-items" href="/shop/cart/">
      <span id="mini-cart-background" class="mini-cart-background">
        <span class="yit-mini-cart-icon"><span class="cart-items-number">0</span></span>
      </span>
    </a>

    <div id="cart_wrapper" class="cart_wrapper" v-show="false">
      <div class="widget_shopping_cart_content">
        <ul>
          <li v-for="item in cart.items" v-if="cart.items.length > 0">
            <span>{{ item.shopItem.title }}</span>&nbsp;<span>{{ item.quantity }}</span>&nbsp;<span>{{
              item.sum
            }}</span>
          </li>
          <li class="empty" v-else>В корзине нет товаров</li>
        </ul>
      </div>
    </div>

  </div>
</template>

<script lang="ts">
interface CartShopItem {
  id: number
  title: string
  price?: number | string
}

interface CartItem {
  quantity?: number
  sum?: number | string
  shopItem: CartShopItem
}

interface Cart {
  items: Array<CartItem>
  total?: number | string
}

const cart = {
  total: 0.00,//57941.50,
  items: [
    /*
    {shopItem: {id: 1, title: 'Tion Бризер 4S Plus', price: 57941.50}, quantity: 1, sum: 57941.50},
    {shopItem: {id: 1, title: 'goods #3'}, quantity: 1},
    {shopItem: {id: 1, title: 'Стандартный монтаж бризера 4S'}, sum: 'оплата по факту работ'},
    */
  ] as Array<CartItem>
} as Cart

export default {
  name: 'ShopCart',
  setup() {
    return {cart}
  }
}
</script>

<style scoped>
.mini-cart-background {
  width: 29px;
  height: 25px;
  display: block;
  background-image: url('~/assets/icons/cart.svg');
  background-position: center;
  background-repeat: no-repeat;
  margin: 3px 0 auto auto;
  background-size: 29px 25px;
  text-align: center;
  position: relative;
}

.mini-cart-background > span {
  display: block;
  text-align: center;
  width: 100%;
  height: 100%;
}

.mini-cart-background > span > span {
  position: absolute;
  top: -6px;
  left: 13px;
}

.cart_label.not-empty .mini-cart-background {
  background-image: url('~/assets/icons/cart-white.svg');
}

.cart_label.not-empty.active .mini-cart-background, .cart_label.loading .mini-cart-background {
  background-image: url('~/assets/icons/cart-green.svg');
}


/*
.w-b-r .dropdown-toggle::after{
  font-size:11px;
  top:2px;
}
.second.user-block-login-cart{
  position:relative;
  width:77px;
  height:28px;
  z-index:5;
}
.first.w-b-r{
  margin-right:6px;
}
.login-button{
  display:inline-block;
  width:29px;
  height:26px;
  margin-top:2px;
}

.widget_shopping_cart{
  position:absolute;
  right:-7px;
  top:0;
  width:40px;
  height:30px;
}
.cart-items{
  text-align:center;
}
.cart-items-number{
  color:#666;
  position:absolute;
  font-size:12px;
  font-weight:300;
  left:0;
  right:0;
}
.widget_shopping_cart .yit-mini-cart-icon{
  position:relative;
  width:43px;
  height:16px;
  display:block;
  margin-left:-3px;
}


/* cart label *//*
.widget_shopping_cart .cart_label{
  padding:0;
  background:transparent;
  border:1px solid transparent;
  border-bottom:0;
  z-index:899;
  position:absolute;
  top:0;
  right:7px;
  border-radius:4px;
  height:30px;
  width:43px;
  cursor:pointer;
  -moz-transition:box-shadow .3s cubic-bezier(0.45, 0.05, 0.55, 0.95);
  -ms-transition:box-shadow .3s cubic-bezier(0.45, 0.05, 0.55, 0.95);
  -o-transition:box-shadow .3s cubic-bezier(0.45, 0.05, 0.55, 0.95);
  transition:box-shadow .3s cubic-bezier(0.45, 0.05, 0.55, 0.95);
}
.widget_shopping_cart .cart_label > a{
  z-index:906;
  position:absolute;
  margin-left:4px;
}
/* cart content *//*
.widget_shopping_cart .cart_label + .cart_wrapper{
  position:absolute;
  display:block;
  right:0;
  top:35px;
  border:1px solid #9c3;
  min-width:440px;
  margin-top:-3px;
  z-index:895;
  background:#fff;
  border-radius:4px;
  padding:5px 0 0;
  border-top-right-radius:0;
  opacity:0;
  visibility:hidden;
  -webkit-transition:visibility 0s 0.3s, opacity .3s cubic-bezier(0.45, 0.05, 0.55, 0.95);
  -moz-transition:visibility 0s 0.3s, opacity .3s cubic-bezier(0.45, 0.05, 0.55, 0.95);
  -ms-transition:visibility 0s 0.3s, opacity .3s cubic-bezier(0.45, 0.05, 0.55, 0.95);
  -o-transition:visibility 0s 0.3s, opacity .3s cubic-bezier(0.45, 0.05, 0.55, 0.95);
  transition:visibility 0s 0.3s, opacity .3s cubic-bezier(0.45, 0.05, 0.55, 0.95);
}
.widget_shopping_cart .cart_label.not-empty.active + .cart_wrapper, .widget_shopping_cart .cart_label.loading + .cart_wrapper{
  opacity:0.95;
  visibility:visible;
  -webkit-transition:visibility 0s, opacity .3s cubic-bezier(0.45, 0.05, 0.55, 0.95);
  -moz-transition:visibility 0s, opacity .3s cubic-bezier(0.45, 0.05, 0.55, 0.95);
  -ms-transition:visibility 0s, opacity .3s cubic-bezier(0.45, 0.05, 0.55, 0.95);
  -o-transition:visibility 0s, opacity .3s cubic-bezier(0.45, 0.05, 0.55, 0.95);
  transition:visibility 0s, opacity .3s cubic-bezier(0.45, 0.05, 0.55, 0.95);
}
.widget_shopping_cart .cart_label + .cart_wrapper:before{
  content:" ";
  display:block;
  width:41px;
  height:12px;
  border:1px solid #fff;
  border-bottom:0;
  background-color:#fff;
  position:absolute;
  right:0;
  top:-6px;
}
.widget_shopping_cart .cart_label.not-empty{
  background:#9c3;
  border-bottom-left-radius:4px;
  border-bottom-right-radius:4px;
  -webkit-box-shadow:0 0 2px 0 rgb(153, 204, 52);
  -moz-box-shadow:0 0 2px 0 rgb(153, 204, 52);
  box-shadow:0 0 2px 0 rgb(153, 204, 52);
}
.widget_shopping_cart .cart_label.not-empty span.cart-items-number{
  color:#fff;
}
.widget_shopping_cart .cart_label.not-empty.active span.cart-items-number, .widget_shopping_cart .cart_label.loading span.cart-items-number{
  color:#9c3;
}
.widget_shopping_cart .cart_label.not-empty.active, .widget_shopping_cart .cart_label.loading{
  -webkit-box-shadow:none;
  -moz-box-shadow:none;
  box-shadow:none;
}
.widget_shopping_cart .cart_label:after{
  content:" ";
  display:block;
  width:43px;
  height:33px;
  border:1px solid #9c3;
  border-bottom:0;
  background-color:#fff;
  margin-top:-1px;
  margin-left:-1px;
  position:absolute;
  right:-1px;
  top:0;
  opacity:0;
  z-index:900;
  border-radius:4px;
  border-bottom-left-radius:0;
  border-bottom-right-radius:0;
  visibility:hidden;
  -webkit-transition:visibility 0s 0.3s, opacity .3s cubic-bezier(0.45, 0.05, 0.55, 0.95);
  -moz-transition:visibility 0s 0.3s, opacity .3s cubic-bezier(0.45, 0.05, 0.55, 0.95);
  -ms-transition:visibility 0s 0.3s, opacity .3s cubic-bezier(0.45, 0.05, 0.55, 0.95);
  -o-transition:visibility 0s 0.3s, opacity .3s cubic-bezier(0.45, 0.05, 0.55, 0.95);
  transition:visibility 0s 0.3s, opacity .3s cubic-bezier(0.45, 0.05, 0.55, 0.95);
}
.widget_shopping_cart .cart_label.not-empty.active:after, .widget_shopping_cart .cart_label.loading:after{
  opacity:1;
  visibility:visible;
  -webkit-transition:visibility 0s, opacity .3s cubic-bezier(0.45, 0.05, 0.55, 0.95);
  -moz-transition:visibility 0s, opacity .3s cubic-bezier(0.45, 0.05, 0.55, 0.95);
  -ms-transition:visibility 0s, opacity .3s cubic-bezier(0.45, 0.05, 0.55, 0.95);
  -o-transition:visibility 0s, opacity .3s cubic-bezier(0.45, 0.05, 0.55, 0.95);
  transition:visibility 0s, opacity .3s cubic-bezier(0.45, 0.05, 0.55, 0.95);
}
ul.cart_list.product_list_widget{
  list-style:none;
  margin:0;
  padding:0 15px;
}
.product_list_widget li{
  overflow:visible;
  margin-left:10px;
  padding:8px 0 4px;
  position:relative;
  margin-bottom:2px;
  min-width:65px;
  border-bottom:1px dashed #9c3;
}
.product_list_widget li.empty{
  padding:20px 0;
  border-color:transparent;
}
.blockUI.blockOverlay:before{
  height:12px;
  width:80px;
  position:absolute;
  top:50%;
  left:50%;
  margin-left:-40px;
  margin-top:-6px;
  display:block;
  content:"";
  background:url('~/assets/img/search.gif') no-repeat center;
  line-height:1;
  text-align:center;
  font-size:2em;
  color:rgba(0, 0, 0, .75);
}
.widget_shopping_cart_content .cart_list li > a.remove{
  position:absolute;
  left:-19px;
  top:15px;
  margin:0;
  width:10px;
  height:10px;
  background:url('~/assets/icons/cross-green.svg') center top no-repeat;
  transition:none;
  text-indent:-9999px;
  float:left;
}
.widget_shopping_cart_content .cart_list li > a.remove:hover{
  background:url('~/assets/icons/cross-red.svg') center top no-repeat;
}
ul.cart_list.product_list_widget .mini-cart-thumb{
  display:none;
}
.widget_shopping_cart_content .mini-cart-item-info{
  float:none;
  width:100%;
  padding-left:0;
  display:inline-block;
  min-width:199px;
  line-height:1.5em;
  font-size: 16px;
  font-family: 'Roboto Condensed', sans-serif;
  text-transform: uppercase;
  font-weight: 400;
  color: #383838;
}
dl.variation{
  display:none;
}
.widget_shopping_cart_content a.button.wc-forward.btn-flat{
  background-color:#9c3;
  border:0;
  border-color:#9c3;
  border-top-style:solid;
  border-top-width:1px;
  border-bottom-right-radius:4px;
  border-bottom-left-radius:4px;
  text-transform:unset;
  font-weight:300;
  display:inline-block;
  -webkit-transition:background-color 0.3s;
  -moz-transition:background-color 0.3s;
  transition:background-color 0.3s;
  text-align:center;
  width:100%;
  font-size:16px;
  padding:7px 18px;
  border-top-right-radius:0;
  border-top-left-radius:0;
  color:#fff;
}
.widget_shopping_cart_content a.button.wc-forward.btn-flat:hover{
  background-color:#fff !important;
  color:#9c3 !important;
}
.widget_shopping_cart_content .quantity{
  min-width:0 !important;
  max-width:none !important;
  width:auto !important;
  float:left;
}
.widget_shopping_cart_content p.total{
  text-align:right;
  font-size:14px;
  font-weight:400;
  border-top:none;
  width:100%;
  margin-top:10px;
  padding-left:15px;
  padding-right:15px;
  height:23px;
}
.widget_shopping_cart_content p.total span{
  text-align:left;
  text-transform:uppercase;
  font-weight:400;
  font-family:'Roboto Condensed';
  color:#000;
  font-size:16px;
}
.widget_shopping_cart_content p.buttons{
  margin-bottom:0;
}
.widget_shopping_cart_content .mini-cart-item-info > a, .widget_shopping_cart_content .mini-cart-item-info > .mini-cart-item-subtotal .subtotal{
  float:left;
  font-size:16px;
  font-family:'Roboto Condensed';
  text-transform:uppercase;
  font-weight:400;
  color:#383838;
  cursor:default;
}
.widget_shopping_cart_content .mini-cart-item-info > .mini-cart-item-subtotal{
  float:right;
  display:block;
}
.widget_shopping_cart_content .mini-cart-item-info > .mini-cart-item-subtotal .subtotal{
  min-width:75px;
  text-align:right;
}
.widget_shopping_cart_content .mini-cart-item-subtotal:before, .widget_shopping_cart_content .mini-cart-item-subtotal:after{
  content:'';
  display:block;
  clear:both;
}
.product_list_widget .mini-cart-item-subtotal > .quantity > span{
  display:none;
}
.product_list_widget .mini-cart-item-subtotal > .quantity > span.qty-input{
  display:block;
  width:71px;
  height:20px;
  position:relative;
  padding:0 22px;
}
.widget_shopping_cart_content .quantity .qty-input button{
  width:22px;
  height:22px;
  padding:0;
  display:block;
  vertical-align:top;
  line-height:1;
  background:#fff;
  color:#aaa;
  border:1px solid #ddd;
  position:absolute;
  top:0;
  font-size:16px;
  font-weight:300;
}
.widget_shopping_cart_content .quantity .qty-input button{
  background:#fff;
  color:#000;
  font-size:26px;
  line-height:21px;
  border-color:transparent;
  top:-1px;
}
.widget_shopping_cart_content .quantity .qty-input button:hover{
  background:#fff;
  color:#666;
  cursor:pointer;
}
.widget_shopping_cart_content .quantity .qty-input button.btn-minus{
  left:0;
}
.widget_shopping_cart_content .quantity .qty-input button.btn-plus{
  right:0;
}
.widget_shopping_cart_content .quantity .qty-input button.btn-plus{
  font-size:20px;
}
.widget_shopping_cart_content .quantity .qty-input input{
  text-align:center;
  -moz-appearance:textfield;
  background-color:#9c3;
  color:#fff;
  border:1px solid #9c3;
  border-radius:4px;
  height:25px;
  width:25px;
  margin-top:-2px;
  margin-left:2px;
  position:absolute;
  font-size:16px;
  font-weight:300;
  font-family:'Roboto Condensed', sans-serif;
}
:focus{
  outline:none;
  text-decoration:none !important;
}
input[type=number]::-webkit-inner-spin-button, input[type=number]::-webkit-outer-spin-button{
  -webkit-appearance:none;
  margin:0;
}
.widget_shopping_cart_content .product_list_widget .mini-cart-item-info > .mini-cart-item-subtotal.montage-mini-subtotal > .quantity{
  display:none;
}
.widget_shopping_cart_content .product_list_widget .mini-cart-item-info a.montage-linker{
  font-size:14px;
  color:#9c3;
  cursor:pointer;
  text-transform:lowercase;
}
.cash-po-fact{
  color:#a4a4a4;
  font-weight:400;
  position:absolute;
  top:10px;
  right:0;
  white-space:nowrap;
  line-height:1;
  text-align:right;
  font-size:11px;
}
/* promocode input *//*
.gds-promo-input{
  position:relative;
}
.gds-promo-input .promo-popup-wrapper{
  position:absolute;
  right:0;
  margin-top:10px;
  background:#eee;
  border-top:3px solid #888;
  padding:8px 30px 8px 8px;
  width:300px;
  display:none;
  z-index:999999;
  max-width:90vw;
}
.gds-promo-input .promo-popup-wrapper.promo-yes{
  border-color:#f6d02c;
  background-color:#f6fcdf;
  display:block;
}
.gds-promo-input .promo-popup-wrapper.promo-no{
  border-color:#e52526;
  background-color:#ffe4e0;
  display:block;
}
.gds-promo-input .promo-popup-wrapper .promo-popup-close{
  background-repeat:no-repeat;
  background-position:center;
  width:14px;
  height:14px;
  position:absolute;
  right:8px;
  cursor:pointer;
}
.gds-promo-input .promo-popup-wrapper .promo-popup-content{
  font-size:16px;
  color:#444;
}
.gds-promo-input .promo-popup-wrapper.promo-yes .promo-popup-close{
  background-image:url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA4AAAAOCAYAAAAfSC3RAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAQBJREFUeNp8Ut0KAVEQnt3rLfEU7ohCpPwUF8qFeEpJ5DciwuJCkQdRxOX6JofG2cPU7Mycb74zc2aHPM+ja4f6UJf9f4qcEXTNvsUBEZXpJXOn6hXIILeu1YSpq3Br4xMSeB4JMwOpJUgsQRsVEnBWGnkpSG2YmsDP4IQt7lclcHJWJEygF2hDnJ1AirDzISryHCZHZjmCFH0HtkQA5GGmBtJBknxEJXfD2UM/0FvtwVR+tLpB1YyvIkgDjeRChyJOI8f9qogDuQTvN8XVhfzmosB2wFK8Ofpv2ANIagswhinJCevD8ZHUtLmbsW84uHEBP6CP3LB63J2DvNhTgAEAFbCL1cPKvtcAAAAASUVORK5CYII=);
}
.gds-promo-input .promo-popup-wrapper.promo-no .promo-popup-close{
  background-image:url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA4AAAAOCAYAAAAfSC3RAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAWlJREFUeNqEks1Kw0AQgHdTSDCeNOsheYKq1QqCiiJYBXvwVk1t0ffzpxY9+FcsFkWNeqi29hWSgunBU5aIEGcXE7Im6MDCzM58O7Mzg4IgQE5FO7NNYjH9rwNxl45J7pmOmeF31CICUaa9lr4/WEUp0t8hNf9F3WK6nKOPUvCFR0On31UL/Qq5TkC7Wj2EuHziEcmouXPKlHcnwNvkNpbp2G8Pl0JbztKecfKexaxeHgDB/pu6HAYoee8KZYIPgMwIGqddo+7mmR6BP3AL4JW0PwLUAWgmtKW4Uz8YFKBBTYRjl6DLE7QdhxIglwzyBJCxEqK/w8RSq+QURrORVqqSow/6obuUyAjQeRyCWVnQoIuo272hRac8ZgkZAYqWgEPsT0fuLH+wQpoworXIN0mfYIQL2ClrwhjA8QyOeWEBqqQBD6/HOyw0Jw3i3d4bFKHshnDJl7es3dgl8vrvkkN19iZpM/1bgAEAY3LbZ6hDmsEAAAAASUVORK5CYII=);
}
.gds-promo-input{
  position:relative;
}
.woocommerce .gds-promo-input > input[type="text"],
.gds-promo-input > input[type="text"]{
  height:34px;
  box-sizing:border-box;
  border-color:#99cc34;
  color:#444;
  font-weight:400;
  width:100%;
  max-width: 185px;
  font-size:16px;
  font-family:'Roboto Condensed', sans-serif;
}
.woocommerce .gds-promo-input > input[type="text"]:focus,
.gds-promo-input > input[type="text"]:focus{
  border-color:#99cc34;
  box-shadow:0 0 10px 0 #99cc34;
}
#promo_input_zone input{
  width:100%;
  border:1px solid #9c3;
  text-align:center;
  height:36px;
  border-left:0;
  border-right:0;
  font-family:'Roboto Condensed', sans-serif;
}
#promo_input_zone .gds-promo-input .promo-popup-wrapper{
  top:-54px !important;
  left:7px;
  width:230px;
  height:38px;
  line-height:15px;
}
.call-text-num:before{
  content:' ';
  height:40px;
  background:url('~/assets/icons/phone.svg') left center no-repeat;
  position:absolute;
  width:37px;
  margin-top:-1px;
  right:108px;
}

 */
/* media *//*
@media (max-width:1199px){

}
@media (max-width:991px){

}
@media (max-width:767px){

}
@media (max-width:480px){
  .woocommerce .gds-promo-input > input[type="text"],
  .gds-promo-input > input[type="text"]{
    height:34px;
    font-size:16px;
  }

  .gds-add-to-cart-button,
  .gds-add-to-cart-button.cartisempty,
  .gds-go-to-checkout-page-button{
    height:34px;
  }
}
@media (max-width:320px){
  .gds-promo-input > input[type="text"] {
    font-size: 13px !important;
  }
}

@media (max-width: 575px) {
  .widget_shopping_cart{
    right: -10px;
  }
}
*/

</style>
