import {loadFromStore, saveToStore} from '../objects/utils'

export const __userStoreKey__ = 'user'

export function getToken(): string {
  let user = loadFromStore(__userStoreKey__)
  return user && user.accessToken ? user.accessToken : ''
}

export const user = {
  namespaced: true,
  state: {
    user: loadFromStore(__userStoreKey__),
  },
  mutations: {
    update(state, user) {
      state.user = user
      saveToStore(__userStoreKey__, user)
    },
  },
  actions: {
    login({commit}, user) {
      commit('update', user)
    },
    logout({commit}) {
      commit('update', {})
    }
  },
}
