import {ref} from "vue";
import {saveMailSubscription} from '../services/fetch/mail-subscribe'

export default function MailSubscribe() {
  const errors = ref({acceptToSA: false, email: false})
  const hasError = () => {
    return errors.value.acceptToSA || errors.value.email
  }

  const subscribeData = ref({email: null, acceptToSA: true})
  const success = ref(false)
  const subscribe = () => {
    errors.value['email'] = false
    if (!subscribeData.value.email) {
      errors.value['email'] = true
    }
    errors.value['acceptToSA'] = false
    if (!subscribeData.value.acceptToSA) {
      errors.value['acceptToSA'] = true
    }

    if (!hasError()) {
      saveMailSubscription(subscribeData.value)
        .then(data => success.value = true)
        .catch(error => {
          if (error.data.errors && error.data.errors.email) {
            if (error.status === 422) {
              success.value = true
              return
            }
          }
          errors.value['email'] = true
        })
    }
  }
  return {subscribeData, success, errors, hasError, subscribe}
}
