export interface MenuItemLinkData {
  css: string | null
  value: string
}

export interface MenuItemLinkInterface {
  css(): string | null

  value(): string
}

export class MenuItemLink implements MenuItemLinkInterface {
  private readonly _css: string | null

  private readonly _value: string

  public constructor(value: string, css: string | null) {
    this._value = value
    this._css = css
  }

  public css(): string | null {
    return this._css
  }

  public value(): string {
    return this._value
  }
}
