<template>

  <div id="_tes_content_bg_"></div>

  <LineLoader :loading="loading? loading?.state.loading : false"/>

  <component :is="layout" :component="layout">
    <router-view v-slot="{Component}">
      <suspense>
        <component :is="Component" />
      </suspense>
    </router-view>
  </component>

  <SiteCookies/>

  <div id="_tes_content_modals_"></div>

  <!-- div id="_tes_up_scroll" class="tes-up-scroll tes-up-scroll"></div -->

</template>

<script lang="ts">
import {Loader, loaderStateSymbol} from './states/loader'
import {RouteMeta, useRoute} from 'vue-router'
import {defineComponent, inject, onServerPrefetch, shallowRef, watch} from 'vue'
import SiteCookies from '~/components/SiteCookies.vue'
import LineLoader from '~/components/loader/LineLoader.vue'
import {useHead} from '@vueuse/head'

import {layoutDefault, LayoutInterface} from './objects/Layout'
import LayoutDefault from './layouts/LayoutDefault.vue'
import LayoutBlog from './layouts/LayoutBlog.vue'
import {pageHeadersStateSymbol, PageHeadsStore} from './states/page-heads'
import {useStore} from "vuex";

export default defineComponent({
  name: 'App',
  components: {
    SiteCookies,
    LineLoader,
    LayoutDefault,
    LayoutBlog
  },
  setup() {
    const loading: Loader | undefined = inject(loaderStateSymbol)
    const head: PageHeadsStore | undefined = inject(pageHeadersStateSymbol)
    const store = useStore()

    if (head) {
      useHead(head.state)
    }

    let currentLayoutData : LayoutInterface | null = store.getters['layout/layoutData'];

    const layout = shallowRef( )
    if (currentLayoutData) {
      switch (currentLayoutData.tid) {
        case 'layout-blog':
          layout.value = LayoutBlog
          break;
        default:
          layout.value = LayoutDefault
      }
    }
    const route = useRoute()

    const loadLayout = async (meta: RouteMeta) => {
      currentLayoutData = store.getters['layout/layoutData'];

      try {

        let layoutData : LayoutInterface | null = (meta && meta.layout && typeof meta.layout === 'object')
          ? meta.layout as LayoutInterface
          : layoutDefault

        if (layout.value && layoutData && currentLayoutData && currentLayoutData.tid === layoutData.tid) {
          return;
        }


        let component = layoutData ? await layoutData.template() : null


        if (component) {
          if (layoutData) {
            await store.dispatch('layout/changeData', layoutData)
          }
          layout.value = component.default
        }

      } catch (e) {
        console.error(e)
      }

      if (!layout.value) {
        let component = await layoutDefault.template()
        if (component) {
          await store.dispatch('layout/changeData', layoutDefault)
          layout.value = (await layoutDefault.template()).default
        }
      }
    }

    watch(() => route.meta, loadLayout, {immediate: true, deep: false} )

    onServerPrefetch(async () => {
      await loadLayout(route.meta)
    })

    return {layout, loading}
  }

})
</script>

<!-- style lang="scss" src="~/styles/main.scss" / -->
