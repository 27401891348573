import {Module} from 'vuex'
import {CommentData} from '../objects/comment/Comment'
import {generateKeyForObject} from '../objects/utils'
import {getCommentsData} from '../services/fetch/comment'
import {PaginationData} from '../objects/Paginator'

export function createCommentsStore():Module<any, any> {
  return {
    namespaced: true,
    state: {
      comments: {} as { data: Array<CommentData>, meta: PaginationData },
      key: null as string | null
    },
    mutations: {
      groupedCommentsData(state, payload: { key: string, comments: Array<{ data: Array<CommentData>, meta: PaginationData }> }){
        state.key = payload.key
        state.comments = payload.comments
      }
    },
    getters: {
      getDataForGroupedComments: (state) => (key): { data: Array<CommentData>, meta: PaginationData } | null => {
        return state.key === key ? state.comments : null
      },
    },
    actions: {
      loadDataForGroupedComments(
        {state, getters, commit},
        {type, paginator, sort, filter}
      ): Promise<{ data: Array<CommentData>, meta: PaginationData }> {
        let key = generateKeyForObject({type, paginator, sort, filter})
        let commentsData = getters['getDataForGroupedComments'](key)
        if (commentsData) {
          return (new Promise<{ data: Array<CommentData>, meta: PaginationData }>((resolve) => resolve(commentsData)))
        }
        return getCommentsData(type, paginator, sort, filter).then(async data => {
          await commit('groupedCommentsData', {key: key, comments: data})
          return data
        })
      },
    }
  }
}
