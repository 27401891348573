<template>
  <div ref="refTags">
    <router-link :to="{name: 'BlogTag', params: {tid: tag.tid()}}" v-for="tag in tags">{{ tag.title() }}</router-link>
    <EllipsisLoader :loading="loading"/>
  </div>
</template>

<script lang="ts">
import PostTag, {PostTagData, PostTagInterface} from '../../objects/post/PostTag'
import {getPostsTagsData} from '../../services/fetch/post-tags'
import {PaginatorData} from '../../objects/Paginator'
import {onMounted, ref} from 'vue'

export default {
  name: 'TagsLinksList',
  setup() {
    const loading = ref(false)
    const tags = ref([] as Array<PostTagInterface>)
    const refTags = ref()

    const load = async () => {
      loading.value = true
      try {
        let data = await getPostsTagsData({}, {page: 1, per_page: 999} as PaginatorData, null, null);
        data.forEach(tagData => tags.value.push(new PostTag(tagData as PostTagData)))
      } catch (e) {
        console.log(e)
      }
      loading.value = false
    }

    onMounted(() => {
      let options = {root: null, threshold: 0}
      let observer = new IntersectionObserver((entities, observer) => {
        entities.forEach((entity) => {
          if (!entity.isIntersecting) {
            return
          }
          observer.unobserve(refTags.value) // прекращаем следить за элементом
          load() //загружаем его
        })
      }, options)
      observer.observe(refTags.value)
    })
    return {refTags, loading, tags}
  }
}
</script>
