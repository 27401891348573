import {Module} from 'vuex'
import {PostListItemData} from '../objects/post/PostListItem'
import {PostData} from '../objects/post/Post'
import {getPostDataById, getPostsData} from '../services/fetch/post'
import {PaginationData} from '../objects/Paginator'
import {generateKeyForObject} from '../objects/utils'
import {preparePicturesInHtml} from '../objects/LazyImage'
import {RatingMetricsInterface} from "../objects/rating/RatingMetrics";

export function createPostsStore():Module<any, any> {
  return {
    namespaced: true,
    state: {
      posts: {} as { [tid: string | number]: Array<PostData> },
      listOfGroupedPosts: {} as { [key: string]: { data: Array<PostListItemData>, meta: PaginationData } },
    },
    mutations: {
      listOfGroupedPostsData(state, payload: { key: string, posts: Array<{ data: Array<PostListItemData>, meta: PaginationData }> }) {
        state.listOfGroupedPosts[payload.key] = payload.posts
      },
      postData(state, post: PostData) {
        state.posts[post.tid] = post
      },
      postDataUpdateRating(state, d : {tid: string, r: RatingMetricsInterface}) {
        if (typeof state.pages[d.tid] !== 'undefined') {
          state.pages[d.tid].voice_sum = d.r.sum();
          state.pages[d.tid].voice_count = d.r.count();
          state.pages[d.tid].voice_average = d.r.average();
        }
      },
    },
    getters: {
      getDataForListOfGroupedPosts: (state) => (key): { data: Array<PostListItemData>, meta: PaginationData } | null => {
        return state.listOfGroupedPosts[key] ?? null
      },
      getDataForPost: (state) => (tid: string | number): PostData | null => {
        return state.posts[tid] ?? null
      }
    },
    actions: {
      loadDataForPost({state, getters, commit}, {tid, options}): Promise<PostData> {
        let postData = getters['getDataForPost'](tid)
        if (postData) {
          return (new Promise<PostData>((resolve) => resolve(postData)))
        }
        return getPostDataById(tid, options).then(async data => {

          data.content = preparePicturesInHtml(data.content,'tes-article__picture_single', 'tes-article__picture_single__loading')

          await commit('postData', data)
          return data
        })
      },
      loadDataForListOfGroupedPost({state, getters, commit}, {
        props,
        paginator,
        sort,
        filter
      }): Promise<{ data: Array<PostListItemData>, meta: PaginationData }> {
        let key = generateKeyForObject({props, paginator, sort, filter})
        let postsData = getters['getDataForListOfGroupedPosts'](key)
        if (postsData) {
          return (new Promise<{ data: Array<PostListItemData>, meta: PaginationData }>((resolve) => resolve(postsData)))
        }
        return getPostsData(props, paginator, sort, filter).then(async data => {
          await commit('listOfGroupedPostsData', {key: key, posts: data})
          return data
        })
      },
    },
  }
}
