import {Module} from 'vuex'
import {PageContentData, PageData, PageTextData} from '../objects/Page'
import {getPageDataById} from '../services/fetch/page'
import {preparePicturesInHtml} from '../objects/LazyImage'
import {RatingMetricsInterface} from "../objects/rating/RatingMetrics";

export function createPagesStore(): Module<any, any> {
  return {
    namespaced: true,
    state: {
      pages: {} as { [path: string | number]: Array<PageData> },
    },
    mutations: {
      pageData(state, page: PageData) {
        state.pages[page.path] = page
      },
      pageDataUpdateRating(state, d : {path: string, r: RatingMetricsInterface}) {
        if (typeof state.pages[d.path] !== 'undefined') {
            state.pages[d.path].voice_sum = d.r.sum();
            state.pages[d.path].voice_count = d.r.count();
            state.pages[d.path].voice_average = d.r.average();
        }
      },
    },
    getters: {
      getDataForPage: (state) => (path: string | number): PageData | null => {
        return state.pages[path] ?? null
      }
    },
    actions: {
      loadDataForPage({state, getters, commit}, {path, options}): Promise<PageData> {
        let pageData = getters['getDataForPage'](path)
        if (pageData) {
          return (new Promise<PageData>((resolve) => resolve(pageData)))
        }
        return getPageDataById(path, options).then(async (data: PageData) => {
          if (data.content) {
            data.content.forEach((item: PageContentData, num: number) => {
              if (item.type_tid === 'page-text' && item.content) {
                let c:PageTextData = item.content as PageTextData
                c.content = preparePicturesInHtml(
                   c.content,
                  'tes-article__picture_single',
                  'tes-article__picture_single__loading'
                )
                data.content[num].content = c
              }
            })
          }

          await commit('pageData', data)
          return data
        })
      },
    },
  }
}
