<template>
  <div class="tes-search" @mouseenter="enter" @mouseleave="leave">
    <form class="tes-search__form" action="/" @submit.prevent="submit" autocomplete="off">
      <EllipsisLoader :loading="loading"/>
      <input v-model="searchWord" @input="search" @click="enter"
             type="text" class="tes-search__input" name="s"
             placeholder="Введите строку для поиска"
             title="Поле ввода текста для поиска по статьям"
             autocomplete="off"
      />
      <button type="button" @click="search"
              class="tes-search__button" title="Запустить поиск по статьям">
        <SearchIcon title="Иконка поиска" class="tes-search__button-icon"/>
        <span class="not-for-anyone">Запустить поиск по статьям</span>
      </button>
    </form>

    <div class="tes-search__results-container" v-show="visibleResults">
      <div v-if="posts.length === 0" class="tes-search__result tes-search__result_empty">
        <span class="tes-search__result-text">ничего не найдено</span>
      </div>
      <div class="tes-search__result" v-for="post in posts" v-else>
        <router-link class="tes-search__result-item-link"
                     :to="{name: 'BlogPost', params: {tid: post.tid()}}">
            <span class="tes-search__result-image-container">
            <img class="tes-search__result-image"
                 :src="post.image()?.src()"
                 :width="post.image()?.size().width()"
                 :height="post.image()?.size().height()"
                 :alt="post.image()?.alt()??''" v-if="post.image()"/>
            </span>
          <span class="tes-search__result-description">
              <span class="tes-search__result-text">{{ post.header() }}</span>
              <span
                class="tes-search__result-text tes-search__result-text_small"
              >{{ post.publishedAt()?.toLocaleDateString('ru') }}</span>
            </span>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script lang="ts">

import PostSearch from "../../composables/post-search"
import EllipsisLoader from "../loader/EllipsisLoader.vue"
import SearchIcon from '~/assets/icons/search-n.svg'

export default {
  name: 'MainPostSearchForm',
  components: {
    SearchIcon,
    EllipsisLoader
  },
  setup() {
    return PostSearch(5)
  }
}
</script>
<style lang="scss" scoped>

.tes-search {
  width: 600px;
  position: relative;
  margin: 0 auto 0;
  padding: 50px 0 60px;
  @media (max-width: 640px) {
    width: 100%;
  }

  &__form {
    border: none;
    display: flex;
  }

  &__input {
    width: 100%;
    border: 0;
    outline: none;
    border-bottom: 1px solid rgba(0, 0, 0, 0.3);
    background: transparent;

    &:focus {
      outline: none;
    }

    &::placeholder,
    &::-webkit-input-placeholder,
    &::-moz-placeholder,
    &:-ms-input-placeholder /*,
    &::-o-input-placeholder*/
    {
      font-weight: 300;
      font-size: 20px;
      line-height: 40px;
      color: rgba(245, 245, 245, 0.25);
    }
  }

  &__button {
    bottom: 6px;
    border: none;
    background: none;
    outline: none;

    &:focus {
      outline: none;
    }
  }

  &__button {
    height: 29px;
    width: auto;
    padding-right: 0;
  }

  &__button-icon {
    height: 29px;
    width: 29px;
  }

  &__results-container {
    width: 100%;
    margin: auto;
    padding: 0;
    position: absolute;
    /*top: 80px;*/
    left: 0;
    z-index: 101;
    text-align: left;
    color: #fff;
    background: #fff;
    border: 1px solid #e6e6e6;
    box-shadow: 1px 14px 15px 0 #9b9b9b;
  }

  &__result {

    position: relative;
    padding: 6px 15px 6px;
    border: none;

    &:hover {
      background: rgba(48, 202, 244, 0.12);
    }

    &_empty {
      display: flex;
      justify-content: center;
      align-items: center;

      &:hover {
        background: none;
      }
    }

    &-item-link {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    &-image-container {
      width: 69px;
      height: auto;
      margin-right: 15px;
    }

    &-image {
      object-fit: cover;
      max-width: 100%;
      max-height: 100%;
      width: auto;
      height: auto;
      vertical-align: middle;
      aspect-ratio: $postsLoadSearchAspectRatio;
    }

    &-description {
      flex: 1;
      display: flex;
      flex-direction: column;
    }

    &-text {
      line-height: 1rem;
      font-size: 0.875rem;
      font-weight: 400;
      text-transform: uppercase;
      color: #373737;
    }

    &-text_small {
      font-size: 0.686rem;
      color: #b1b1b1;
    }
  }
}

</style>
