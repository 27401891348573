<style lang="scss">
.tes {
  &-where-buy-button {
    font-weight: 300;
    font-size: 14px;
    line-height: 19px;
    display: inline-block;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    user-select: none;
    border-radius: .25rem;
    width: 92px;
    height: 30px;
    color: #11404e;
    background: #30cbf2;
    border: 1px solid #30cbf2;
    cursor: pointer;
    padding: 4px 12px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    -webkit-transition: all .2s ease-in-out;
    -o-transition: all .2s ease-in-out;
    transition: all .2s ease-in-out;

    &:hover, &_active {
      background: #fff !important;
      border: 1px solid #fff;
      color: #003f4e !important;
    }
  }

  &-where-buy-list {
    position: absolute;
    z-index: 1000;
    min-width: 10rem;
    padding: 0.5rem 0;
    margin: 8px 0 0.125rem;
    font-size: 1rem;
    color: #212529;
    text-align: left;
    list-style: none;
    background-color: rgba(255, 255, 255, 0.98);
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, 0.15);
    @include menuBoxShadow();
  }

  &-where-buy-item {
    @include colorTransition();

    font-size: 15px;
    line-height: 24px;
    font-style: normal;
    text-align: left;
    display: block;
    font-weight: 300;
    color: #000;
    padding: 0;

    &:hover,
    &_active {
      background-color: transparent;
      color: #00c9fc;
    }

    a {
      @include colorTransition();

      font-size: 15px;
      line-height: 24px;
      font-style: normal;
      text-align: left;
      display: block;
      font-weight: 300;
      padding: 0 24px;
      color: #000;

      &:link,
      &:visited {
        color: #000;
      }

      &:hover,
      &:focus,
      &.active {
        color: #30cbf2;
      }
    }

    &:hover,
    &:focus,
    &_active {
      > a {
        background-color: transparent;
        color: #00c9fc !important;
      }
    }

  }
}
</style>

<template>
  <div @mouseleave="leave">
    <button type="button" role="button"
            :class="['tes-where-buy-button', show?'tes-where-buy-button_active':'']"
            @mouseenter="enter">{{ menuData.title }}
    </button>

    <transition name="fade">
      <ul class="tes-where-buy-list" v-show="show"
          @mouseenter="enter">
        <li v-for="item in menuData.items" v-bind:key="item.id" class="tes-where-buy-item">
          <a :href="item.link.value">{{ item.title }}</a>
        </li>
      </ul>
    </transition>
  </div>
</template>

<script lang="ts">

import {MenuData} from "../objects/menu/Menu";
import {MenuItemData} from "../objects/menu/MenuItem";
import {Ref, ref} from "vue";

const menuData = {
  tid: 'where-buy',
  title: 'Где купить',
  items: [
    {id: 10001, link: {value: '/shop/'}, title: 'Интернет-магазин'} as MenuItemData,
    {id: 10002, link: {value: '/partnership/partners_all/'}, title: 'Дилеры Тион'} as MenuItemData,
    {id: 10003, link: {value: '/partnership/retail/'}, title: 'Розница'} as MenuItemData
  ] as Array<MenuItemData>
} as MenuData

export default {
  name: 'WhereBuy',
  setup() {
    let show: Ref<boolean> = ref(false)
    let timer: number | null = null
    const enter = () => {
      show.value = true
      if (timer != null) {
        window.clearTimeout(timer)
        timer = null
      }
    }
    const leave = () => {
      timer = window.setTimeout(() => {
        show.value = false
      }, 300)
    }
    return {show, menuData, enter, leave}
  }
}
</script>
